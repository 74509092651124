import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { 
  FaBoxOpen, 
  FaSearch, 
  FaEyeSlash, 
  FaWarehouse, 
  FaTruck, 
  FaCog, 
  FaSignOutAlt,
  FaBars,
  FaTimes
} from 'react-icons/fa';

const Header: React.FC = () => {
  const { isAuthenticated, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Only set body overflow when menu state changes
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    
    // Cleanup function to ensure body overflow is reset when component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-white z-50 shadow-md">
      <div className="container mx-auto px-4 py-2 flex items-center justify-between">
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <h1 className="text-xl font-bold">Dizzle</h1>
          </Link>
        </div>
        {isAuthenticated && (
          <>
            {/* Desktop Navigation */}
            <div className="hidden md:flex space-x-6 items-center">
              <Link to="/saved" className="text-blue-600 hover:text-blue-800 flex items-center">
                <FaBoxOpen className="mr-1" />
                <span>Saved Items</span>
              </Link>
              <Link to="/saved-searches" className="text-blue-600 hover:text-blue-800 flex items-center">
                <FaSearch className="mr-1" />
                <span>Saved Searches</span>
              </Link>
              <Link to="/hidden-items" className="text-blue-600 hover:text-blue-800 flex items-center">
                <FaEyeSlash className="mr-1" />
                <span>Hidden Items</span>
              </Link>
              <Link to="/inventory" className="text-blue-600 hover:text-blue-800 flex items-center">
                <FaWarehouse className="mr-1" />
                <span>Inventory</span>
              </Link>
              <Link to="/suppliers" className="text-blue-600 hover:text-blue-800 flex items-center">
                <FaTruck className="mr-1" />
                <span>Suppliers</span>
              </Link>
              <Link to="/admin" className="text-blue-600 hover:text-blue-800 flex items-center">
                <FaCog className="mr-1" />
                <span>Admin</span>
              </Link>
              <button 
                onClick={logout}
                className="ml-4 px-3 py-1 bg-gray-200 rounded hover:bg-gray-300 text-gray-700 flex items-center"
              >
                <FaSignOutAlt className="mr-1" />
                <span>Logout</span>
              </button>
            </div>

            {/* Mobile Hamburger Button */}
            <button 
              className="md:hidden flex items-center"
              onClick={toggleMenu}
              aria-label="Toggle menu"
            >
              {isMenuOpen ? (
                <FaTimes className="w-6 h-6 text-gray-700" />
              ) : (
                <FaBars className="w-6 h-6 text-gray-700" />
              )}
            </button>
          </>
        )}
      </div>

      {/* Mobile Menu Dropdown */}
      <div 
        className={`md:hidden fixed top-[42px] left-0 right-0 bottom-0 bg-white transition-all duration-300 ease-in-out z-40 ${
          isMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
        }`}
      >
        <nav className="flex flex-col p-4 overflow-y-auto max-h-full">
          <Link 
            to="/saved" 
            className="text-blue-600 hover:text-blue-800 py-3 border-b border-gray-100 flex items-center text-lg"
            onClick={() => setIsMenuOpen(false)}
          >
            <FaBoxOpen className="mr-3 w-5 h-5" />
            Saved Items
          </Link>
          <Link 
            to="/saved-searches" 
            className="text-blue-600 hover:text-blue-800 py-3 border-b border-gray-100 flex items-center text-lg"
            onClick={() => setIsMenuOpen(false)}
          >
            <FaSearch className="mr-3 w-5 h-5" />
            Saved Searches
          </Link>
          <Link 
            to="/hidden-items" 
            className="text-blue-600 hover:text-blue-800 py-3 border-b border-gray-100 flex items-center text-lg"
            onClick={() => setIsMenuOpen(false)}
          >
            <FaEyeSlash className="mr-3 w-5 h-5" />
            Hidden Items
          </Link>
          <Link 
            to="/inventory" 
            className="text-blue-600 hover:text-blue-800 py-3 border-b border-gray-100 flex items-center text-lg"
            onClick={() => setIsMenuOpen(false)}
          >
            <FaWarehouse className="mr-3 w-5 h-5" />
            Inventory
          </Link>
          <Link 
            to="/suppliers" 
            className="text-blue-600 hover:text-blue-800 py-3 border-b border-gray-100 flex items-center text-lg"
            onClick={() => setIsMenuOpen(false)}
          >
            <FaTruck className="mr-3 w-5 h-5" />
            Suppliers
          </Link>
          <Link 
            to="/admin" 
            className="text-blue-600 hover:text-blue-800 py-3 border-b border-gray-100 flex items-center text-lg"
            onClick={() => setIsMenuOpen(false)}
          >
            <FaCog className="mr-3 w-5 h-5" />
            Admin
          </Link>
          <button 
            onClick={() => {
              logout();
              setIsMenuOpen(false);
            }}
            className="text-left py-3 text-gray-700 hover:text-gray-900 flex items-center text-lg"
          >
            <FaSignOutAlt className="mr-3 w-5 h-5" />
            Logout
          </button>
        </nav>
      </div>
    </header>
  );
};

export default Header; 