import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Product, ProductCreate, ProductUpdate, ProductImage } from '../types/inventory';
import { inventoryService } from '../services/inventoryService';
import ProductModal from '../components/ProductModal';
import toast from 'react-hot-toast';
import { formatImageUrl } from '../utils/urlUtils';

const InventoryPage: React.FC = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState<Product[]>([]);
    const [productImages, setProductImages] = useState<Record<number, ProductImage[]>>({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();

    useEffect(() => {
        loadProducts();
    }, []);

    const loadProducts = async () => {
        try {
            setLoading(true);
            const data = await inventoryService.listProducts();
            setProducts(data);
            // Load images for each product
            const imagesPromises = data.map(product => 
                inventoryService.getProductImages(product.id)
                    .then(images => ({ productId: product.id, images }))
                    .catch(() => ({ productId: product.id, images: [] }))
            );
            const imagesResults = await Promise.all(imagesPromises);
            
            const imagesMap: Record<number, ProductImage[]> = {};
            imagesResults.forEach(result => {
                imagesMap[result.productId] = result.images;
            });
            
            setProductImages(imagesMap);
        } catch (err) {
            setError('Failed to load products');
            toast.error('Failed to load products');
        } finally {
            setLoading(false);
        }
    };

    const handleCreateProduct = async (product: ProductCreate | ProductUpdate) => {
        try {
            const newProduct = await inventoryService.createProduct(product as ProductCreate);
            toast.success('Product created successfully');
            setIsModalOpen(false);
            loadProducts();
            // Navigate to the details page of the newly created product
            navigate(`/inventory/${newProduct.id}`);
        } catch (err) {
            toast.error('Failed to create product');
        }
    };

    const handleUpdateProduct = async (product: ProductUpdate) => {
        if (!selectedProduct) return;
        try {
            await inventoryService.updateProduct(selectedProduct.id, product);
            toast.success('Product updated successfully');
            setIsModalOpen(false);
            setSelectedProduct(undefined);
            loadProducts();
        } catch (err) {
            toast.error('Failed to update product');
        }
    };

    const handleDeleteProduct = async (productId: number) => {
        if (!window.confirm('Are you sure you want to delete this product?')) return;
        try {
            await inventoryService.deleteProduct(productId);
            toast.success('Product deleted successfully');
            loadProducts();
        } catch (err) {
            toast.error('Failed to delete product');
        }
    };

    const handleEditProduct = (product: Product) => {
        setSelectedProduct(product);
        setIsModalOpen(true);
    };

    const getProductThumbnail = (productId: number): string => {
        const images = productImages[productId] || [];
        const primaryImage = images.find((img: ProductImage) => img.is_primary);
        const image = primaryImage || (images.length > 0 ? images[0] : null);
        
        if (image) {
            return formatImageUrl(image.url);
        }
        return 'https://via.placeholder.com/60x60';
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-red-500">{error}</div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8" style={{ paddingTop: '4rem' }}>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6" style={{ marginTop: '2rem' }}>
                <h1 className="text-2xl font-bold mb-4 sm:mb-0">Inventory Management</h1>
                <button
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded inline-block"
                    style={{ position: 'relative', zIndex: 20 }}
                    onClick={() => {
                        setSelectedProduct(undefined);
                        setIsModalOpen(true);
                    }}
                >
                    Add Product
                </button>
            </div>

            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <div className="overflow-x-auto" style={{ WebkitOverflowScrolling: 'touch', scrollbarWidth: 'thin' }}>
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Product details
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Performance
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Inventory
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {products.map((product: Product) => (
                                <tr key={product.id} className="hover:bg-gray-50 cursor-pointer" onClick={() => navigate(`/inventory/${product.id}`)}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0 h-16 w-16 mr-4">
                                                <img 
                                                    className="h-16 w-16 rounded object-cover" 
                                                    src={getProductThumbnail(product.id)} 
                                                    alt={product.title} 
                                                />
                                            </div>
                                            <div>
                                                <div className="text-sm font-medium text-gray-900">
                                                    {product.title}
                                                </div>
                                                <div className="text-sm text-gray-500">
                                                    ASIN: {product.asin || 'N/A'}, SKU: {product.sku}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-900">Last 30 days</div>
                                        <div className="text-sm text-gray-500">
                                            <div>Sales: --</div>
                                            <div>Units sold: --</div>
                                            <div>Page views: --</div>
                                            <div>Sales rank: --</div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm">
                                            <div className="flex justify-between">
                                                <span>Available (FBA):</span>
                                                <span className="font-medium">{product.quantity_at_fba}</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <span>Inbound:</span>
                                                <span className="font-medium">{product.quantity_in_transit}</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <span>Unfulfillable:</span>
                                                <span className="font-medium">0</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <span>Reserved:</span>
                                                <span className="font-medium">{product.quantity_reserved}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            className="text-blue-600 hover:text-blue-900 mr-3"
                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                e.stopPropagation();
                                                navigate(`/inventory/${product.id}`);
                                            }}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="text-red-600 hover:text-red-900"
                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                e.stopPropagation();
                                                handleDeleteProduct(product.id);
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="px-4 py-2 bg-gray-50 text-sm text-gray-500 md:hidden">
                    <div className="flex items-center justify-center">
                        <span>← Swipe to see more →</span>
                    </div>
                </div>
            </div>

            <ProductModal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setSelectedProduct(undefined);
                }}
                onSubmit={selectedProduct ? handleUpdateProduct : (product: ProductCreate | ProductUpdate) => handleCreateProduct(product)}
                product={selectedProduct}
                title={selectedProduct ? 'Edit Product' : 'Add New Product'}
            />
        </div>
    );
};

export default InventoryPage; 