import React, { useState, useEffect } from 'react';
import { getHiddenItemIds, unhideItem } from '../services/api';
import { Link } from 'react-router-dom';

const HiddenItemsPage: React.FC = () => {
  const [hiddenItemIds, setHiddenItemIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHiddenItems = async () => {
      try {
        setIsLoading(true);
        const ids = await getHiddenItemIds();
        setHiddenItemIds(ids);
      } catch (error) {
        console.error('Error fetching hidden items:', error);
        setError('Failed to load hidden items. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchHiddenItems();
  }, []);

  const handleUnhide = async (id: number) => {
    try {
      await unhideItem(id);
      setHiddenItemIds(prev => prev.filter(itemId => itemId !== id));
    } catch (error) {
      console.error('Error unhiding item:', error);
      setError('Failed to unhide item. Please try again.');
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Hidden Items</h1>
        <Link 
          to="/"
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
        >
          Back to Search
        </Link>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center py-12">
          <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
        </div>
      ) : error ? (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
          {error}
        </div>
      ) : hiddenItemIds.length === 0 ? (
        <div className="bg-white rounded-lg shadow-md p-6 text-center">
          <p className="text-gray-600">You haven't hidden any items yet.</p>
          <p className="text-gray-500 mt-2">
            When you hide items from search results, they will appear here.
          </p>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-md p-6">
          <p className="mb-4">
            You have hidden <span className="font-bold">{hiddenItemIds.length}</span> items from your search results.
          </p>
          
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Thingiverse ID
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Thingiverse Link
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {hiddenItemIds.map((id) => (
                  <tr key={id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600">
                      <a 
                        href={`https://www.thingiverse.com/thing:${id}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="hover:underline"
                      >
                        View on Thingiverse
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => handleUnhide(id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Unhide
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default HiddenItemsPage; 