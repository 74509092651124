import React, { createContext, useState, useEffect, useContext } from 'react';
import { initSecurityMonitor, createSecureToken } from '../utils/security';

interface AuthContextType {
  isAuthenticated: boolean;
  login: (password: string) => boolean;
  logout: () => void;
  loginAttempts: number;
  isLocked: boolean;
  lockoutTimeRemaining: number;
}

interface AuthState {
  token: string;
  expiry: number;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loginAttempts, setLoginAttempts] = useState<number>(0);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [lockoutEnd, setLockoutEnd] = useState<number>(0);
  const [lockoutTimeRemaining, setLockoutTimeRemaining] = useState<number>(0);
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);

  // Constants
  const MAX_LOGIN_ATTEMPTS = 10;
  const LOCKOUT_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds
  const TOKEN_EXPIRY = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds
  
  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('authData');
  };
  
  // Set up security monitoring to prevent tampering with localStorage
  useEffect(() => {
    // Initialize security monitor to prevent tampering with local storage
    const cleanupSecurity = initSecurityMonitor(setIsAuthenticated, logout);
    
    return () => {
      cleanupSecurity();
    };
  }, []);
  
  // Check local storage on initial load and set up timer for expiry check
  useEffect(() => {
    // Initial check of authentication
    checkAuthentication();
    checkLockoutStatus();
    
    // Set auth as checked
    setIsAuthChecked(true);
    
    // Set up interval to check for token expiration
    const tokenCheckInterval = setInterval(() => {
      checkAuthentication();
    }, 60 * 1000); // Check every minute
    
    // Set up interval to update lockout time remaining
    const lockoutInterval = isLocked ? setInterval(() => {
      checkLockoutStatus();
    }, 1000) : null; // Update every second when locked
    
    // Clean up intervals
    return () => {
      clearInterval(tokenCheckInterval);
      if (lockoutInterval) clearInterval(lockoutInterval);
    };
  }, [isLocked]);
  
  const checkAuthentication = () => {
    try {
      const authData = localStorage.getItem('authData');
      if (authData) {
        const parsedData: AuthState = JSON.parse(authData);
        
        // Check if token has expired
        if (parsedData.expiry > Date.now()) {
          setIsAuthenticated(true);
        } else {
          // Token expired
          logout();
        }
      } else {
        // No auth data found
        setIsAuthenticated(false);
      }
    } catch (error) {
      // Invalid stored data
      console.error('Error checking authentication:', error);
      logout();
    }
  };
  
  const checkLockoutStatus = () => {
    const storedLockoutEnd = localStorage.getItem('lockoutEnd');
    if (storedLockoutEnd) {
      const lockoutEndTime = parseInt(storedLockoutEnd, 10);
      const now = Date.now();
      
      if (lockoutEndTime > now) {
        // Still locked out
        setIsLocked(true);
        setLockoutEnd(lockoutEndTime);
        setLockoutTimeRemaining(Math.ceil((lockoutEndTime - now) / 1000));
      } else {
        // Lockout period ended
        setIsLocked(false);
        setLockoutTimeRemaining(0);
        localStorage.removeItem('lockoutEnd');
        // Reset login attempts after lockout period
        setLoginAttempts(0);
        localStorage.removeItem('loginAttempts');
      }
    } else {
      // Load saved login attempts
      const storedAttempts = localStorage.getItem('loginAttempts');
      if (storedAttempts) {
        setLoginAttempts(parseInt(storedAttempts, 10));
      }
    }
  };

  const login = (password: string): boolean => {
    // Check if account is locked
    if (isLocked) {
      return false;
    }
    
    // Hardcoded password check
    if (password === 'Amz35pnc!') {
      // Successful login
      setIsAuthenticated(true);
      
      // Set token with expiration
      const expiry = Date.now() + TOKEN_EXPIRY;
      const authData: AuthState = {
        token: createSecureToken(), // Using our secure token generator
        expiry: expiry
      };
      
      // Store in localStorage
      localStorage.setItem('authData', JSON.stringify(authData));
      
      // Reset login attempts
      setLoginAttempts(0);
      localStorage.removeItem('loginAttempts');
      
      return true;
    } else {
      // Failed login attempt
      const newAttempts = loginAttempts + 1;
      setLoginAttempts(newAttempts);
      localStorage.setItem('loginAttempts', newAttempts.toString());
      
      // Check if we need to lock the account
      if (newAttempts >= MAX_LOGIN_ATTEMPTS) {
        const lockEndTime = Date.now() + LOCKOUT_DURATION;
        setIsLocked(true);
        setLockoutEnd(lockEndTime);
        setLockoutTimeRemaining(LOCKOUT_DURATION / 1000);
        localStorage.setItem('lockoutEnd', lockEndTime.toString());
      }
      
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      login, 
      logout, 
      loginAttempts, 
      isLocked, 
      lockoutTimeRemaining 
    }}>
      {isAuthChecked ? children : (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 