import axios from 'axios';
import {
    Product,
    ProductCreate,
    ProductUpdate,
    Listing,
    ListingCreate,
    ProductImage,
    ProductImageCreate,
    InventoryTransaction,
    InventoryTransactionCreate,
    ProductFormData,
    Supplier,
    ProductLabel,
    ProductLabelCreate,
    ProductPrintFile
} from '../types/inventory';

const API_BASE_URL = process.env.REACT_APP_API_URL || '/api';

export const inventoryService = {
    // Product endpoints
    createProduct: async (product: ProductCreate): Promise<Product> => {
        const response = await axios.post(`${API_BASE_URL}/v1/inventory/products/`, product);
        return response.data;
    },

    getProduct: async (productId: number): Promise<Product> => {
        const response = await axios.get(`${API_BASE_URL}/v1/inventory/products/${productId}`);
        return response.data;
    },

    updateProduct: async (productId: number, product: ProductUpdate): Promise<Product> => {
        console.log('inventoryService.updateProduct called with:', { productId, product });
        console.log('API URL:', `${API_BASE_URL}/v1/inventory/products/${productId}`);
        try {
            const response = await axios.put(`${API_BASE_URL}/v1/inventory/products/${productId}`, product);
            console.log('API updateProduct successful response:', response.data);
            return response.data;
        } catch (error) {
            console.error('API updateProduct error:', error);
            throw error;
        }
    },

    deleteProduct: async (productId: number): Promise<void> => {
        await axios.delete(`${API_BASE_URL}/v1/inventory/products/${productId}`);
    },

    listProducts: async (skip: number = 0, limit: number = 100): Promise<Product[]> => {
        const response = await axios.get(`${API_BASE_URL}/v1/inventory/products/`, {
            params: { skip, limit }
        });
        return response.data;
    },

    // Listing endpoints
    createListing: async (productId: number, listing: ListingCreate): Promise<Listing> => {
        const response = await axios.post(`${API_BASE_URL}/v1/inventory/products/${productId}/listings/`, listing);
        return response.data;
    },

    getProductListings: async (productId: number): Promise<Listing[]> => {
        const response = await axios.get(`${API_BASE_URL}/v1/inventory/products/${productId}/listings/`);
        return response.data;
    },

    // Product Image endpoints
    addProductImage: async (productId: number, image: ProductImageCreate): Promise<ProductImage> => {
        const response = await axios.post(`${API_BASE_URL}/v1/inventory/products/${productId}/images/`, image);
        return response.data;
    },

    uploadProductImage: async (productId: number, formData: FormData): Promise<ProductImage> => {
        const response = await axios.post(
            `${API_BASE_URL}/v1/inventory/products/${productId}/images/`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return response.data;
    },

    getProductImages: async (productId: number): Promise<ProductImage[]> => {
        const response = await axios.get(`${API_BASE_URL}/v1/inventory/products/${productId}/images/`);
        return response.data;
    },

    setPrimaryImage: async (productId: number, imageId: number): Promise<ProductImage> => {
        console.log("setPrimaryImage called with:", { productId, imageId });
        const url = `${API_BASE_URL}/v1/inventory/products/${productId}/images/${imageId}/set-primary/`;
        console.log("Making request to:", url);
        try {
            const response = await axios.put(url, {});
            console.log("Response received:", response.data);
            return response.data;
        } catch (error) {
            console.error("Error in setPrimaryImage:", error);
            throw error;
        }
    },

    deleteProductImage: async (productId: number, imageId: number): Promise<void> => {
        await axios.delete(`${API_BASE_URL}/v1/inventory/products/${productId}/images/${imageId}/`);
    },

    // Inventory Transaction endpoints
    updateInventory: async (productId: number, transaction: InventoryTransactionCreate): Promise<Product> => {
        const response = await axios.post(`${API_BASE_URL}/v1/inventory/products/${productId}/inventory/`, transaction);
        return response.data;
    },

    getInventoryTransactions: async (productId: number): Promise<InventoryTransaction[]> => {
        const response = await axios.get(`${API_BASE_URL}/v1/inventory/products/${productId}/transactions/`);
        return response.data;
    },

    // Inventory Management endpoints
    getLowStockProducts: async (threshold: number = 10): Promise<Product[]> => {
        const response = await axios.get(`${API_BASE_URL}/v1/inventory/low-stock/`, {
            params: { threshold }
        });
        return response.data;
    },

    getProductsNeedingReorder: async (): Promise<Product[]> => {
        const response = await axios.get(`${API_BASE_URL}/v1/inventory/needs-reorder/`);
        return response.data;
    },

    // Supplier API Methods
    getSuppliers: async (): Promise<Supplier[]> => {
        try {
            const response = await axios.get('/api/v1/inventory/suppliers/');
            return response.data;
        } catch (error) {
            console.error('Error fetching suppliers:', error);
            throw error;
        }
    },

    getSupplierById: async (supplierId: number): Promise<Supplier> => {
        try {
            const response = await axios.get(`/api/v1/inventory/suppliers/${supplierId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching supplier ${supplierId}:`, error);
            throw error;
        }
    },

    createSupplier: async (supplierData: Partial<Supplier>): Promise<Supplier> => {
        try {
            const response = await axios.post('/api/v1/inventory/suppliers/', supplierData);
            return response.data;
        } catch (error) {
            console.error('Error creating supplier:', error);
            throw error;
        }
    },

    updateSupplier: async (supplierId: number, supplierData: Partial<Supplier>): Promise<Supplier> => {
        try {
            const response = await axios.put(`/api/v1/inventory/suppliers/${supplierId}`, supplierData);
            return response.data;
        } catch (error) {
            console.error(`Error updating supplier ${supplierId}:`, error);
            throw error;
        }
    },

    deleteSupplier: async (supplierId: number): Promise<void> => {
        try {
            await axios.delete(`/api/v1/inventory/suppliers/${supplierId}`);
        } catch (error) {
            console.error(`Error deleting supplier ${supplierId}:`, error);
            throw error;
        }
    },

    // Product Label endpoints
    uploadProductLabel: async (productId: number, formData: FormData): Promise<ProductLabel> => {
        const response = await axios.post(
            `${API_BASE_URL}/v1/inventory/products/${productId}/labels/`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return response.data;
    },

    getProductLabels: async (productId: number): Promise<ProductLabel[]> => {
        const response = await axios.get(`${API_BASE_URL}/v1/inventory/products/${productId}/labels/`);
        return response.data;
    },

    deleteProductLabel: async (productId: number, labelId: number): Promise<void> => {
        await axios.delete(`${API_BASE_URL}/v1/inventory/products/${productId}/labels/${labelId}/`);
    },

    downloadProductLabel: async (productId: number, labelId: number): Promise<Blob> => {
        const response = await axios.get(
            `${API_BASE_URL}/v1/inventory/products/${productId}/labels/${labelId}/download`,
            { responseType: 'blob' }
        );
        return response.data;
    },

    printProductLabel: async (productId: number, labelId: number): Promise<void> => {
        await axios.post(`${API_BASE_URL}/v1/inventory/products/${productId}/labels/${labelId}/print`);
    },
    
    convertLabelToPdf: async (productId: number, labelId: number): Promise<Blob> => {
        const response = await axios.get(
            `${API_BASE_URL}/v1/inventory/products/${productId}/labels/${labelId}/pdf`,
            { responseType: 'blob' }
        );
        return response.data;
    },

    // Product 3D Print File endpoints
    uploadProductPrintFile: async (productId: number, formData: FormData): Promise<ProductPrintFile> => {
        const response = await axios.post(
            `${API_BASE_URL}/v1/inventory/products/${productId}/print-files/`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return response.data;
    },

    getProductPrintFiles: async (productId: number): Promise<ProductPrintFile[]> => {
        const response = await axios.get(`${API_BASE_URL}/v1/inventory/products/${productId}/print-files/`);
        return response.data;
    },

    deleteProductPrintFile: async (productId: number, printFileId: number): Promise<void> => {
        await axios.delete(`${API_BASE_URL}/v1/inventory/products/${productId}/print-files/${printFileId}/`);
    },

    downloadProductPrintFile: async (productId: number, printFileId: number): Promise<Blob> => {
        try {
            console.log(`Downloading 3D print file: product=${productId}, file=${printFileId}`);
            const response = await axios.get(
                `${API_BASE_URL}/v1/inventory/products/${productId}/print-files/${printFileId}/download`,
                { 
                    responseType: 'blob',
                    headers: {
                        'Accept': 'application/octet-stream'
                    } 
                }
            );
            console.log('Download response status:', response.status);
            console.log('Download content type:', response.headers['content-type']);
            return response.data;
        } catch (error) {
            console.error('Error downloading 3D print file:', error);
            throw error;
        }
    },

    getProductPrintFilePreview: async (productId: number, printFileId: number): Promise<{ file_data: string, file_type: string }> => {
        try {
            console.log(`Getting 3D print file preview: product=${productId}, file=${printFileId}`);
            const response = await axios.get(
                `${API_BASE_URL}/v1/inventory/products/${productId}/print-files/${printFileId}/download?preview=true`,
                {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            );
            console.log('Preview response status:', response.status);
            console.log('Preview data received, type:', typeof response.data);
            if (response.data && response.data.file_data) {
                console.log('Preview data length:', response.data.file_data.length);
            } else {
                console.error('No file_data in response:', response.data);
            }
            return response.data;
        } catch (error) {
            console.error('Error getting 3D print file preview:', error);
            throw error;
        }
    }
}; 