import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';

interface BackupStats {
  success: boolean;
  message: string;
  timestamp?: string;
  images_backed_up?: number;
  labels_backed_up?: number;
  print_files_backed_up?: number;
}

const AdminPage: React.FC = () => {
  const [githubUsername, setGithubUsername] = useState('');
  const [githubToken, setGithubToken] = useState('');
  const [isBackingUp, setIsBackingUp] = useState(false);
  const [backupStatus, setBackupStatus] = useState<string | null>(null);
  const [backupStats, setBackupStats] = useState<BackupStats | null>(null);

  const handleBackup = async () => {
    if (!githubUsername || !githubToken) {
      toast.error('Please enter both GitHub username and personal access token');
      return;
    }

    setIsBackingUp(true);
    setBackupStatus('Starting backup process...');
    setBackupStats(null);

    try {
      // Backend API expects the credentials in the format specified by the GitHubCredentials model
      const response = await axios.post('/api/admin/backup', {
        githubUsername,
        githubToken
      });

      if (response.data.success) {
        setBackupStatus('Backup completed successfully!');
        setBackupStats({
          success: true,
          message: response.data.message,
          timestamp: response.data.timestamp,
          images_backed_up: response.data.images_backed_up,
          labels_backed_up: response.data.labels_backed_up,
          print_files_backed_up: response.data.print_files_backed_up
        });
        toast.success('Database, config files, product images, product labels, and 3D print files backed up to GitHub repository');
      } else {
        setBackupStatus(`Backup failed: ${response.data.message}`);
        toast.error(response.data.message || 'Backup failed');
      }
    } catch (error: any) {
      console.error('Error during backup:', error);
      const errorMessage = error.response?.data?.detail || error.message || 'An unexpected error occurred';
      setBackupStatus(`Backup failed: ${errorMessage}`);
      toast.error(errorMessage);
    } finally {
      setIsBackingUp(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Admin Dashboard</h1>
      
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-4">GitHub Backup</h2>
        <p className="mb-6 text-gray-600">
          This tool will back up your database (app.db), configuration files (hidden_items.json), 
          product images, product labels, and 3D print files to the GitHub repository (https://github.com/ngisvold/dizzle).
          You need to provide your GitHub credentials for this operation.
        </p>
        
        <div className="space-y-4 mb-6">
          <div>
            <label htmlFor="githubUsername" className="block text-sm font-medium text-gray-700 mb-1">
              GitHub Username
            </label>
            <input
              type="text"
              id="githubUsername"
              value={githubUsername}
              onChange={(e) => setGithubUsername(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your GitHub username"
            />
          </div>
          
          <div>
            <label htmlFor="githubToken" className="block text-sm font-medium text-gray-700 mb-1">
              GitHub Personal Access Token
            </label>
            <input
              type="password"
              id="githubToken"
              value={githubToken}
              onChange={(e) => setGithubToken(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your GitHub token"
            />
            <p className="mt-1 text-sm text-gray-500">
              Token must have 'repo' scope permissions. 
              <a 
                href="https://github.com/settings/tokens/new" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline ml-1"
              >
                Create a token
              </a>
            </p>
          </div>
        </div>
        
        <button
          onClick={handleBackup}
          disabled={isBackingUp}
          className={`px-6 py-2 rounded-md text-white font-medium ${
            isBackingUp ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
          }`}
        >
          {isBackingUp ? 'Backing up...' : 'Backup to GitHub'}
        </button>
        
        {backupStatus && (
          <div className={`mt-4 p-3 rounded-md ${
            backupStatus.includes('failed') 
              ? 'bg-red-100 text-red-800' 
              : backupStatus.includes('completed') 
                ? 'bg-green-100 text-green-800'
                : 'bg-blue-100 text-blue-800'
          }`}>
            <div className="font-medium">{backupStatus}</div>
            
            {backupStats && (
              <div className="mt-2 text-sm">
                <div>Backup timestamp: {backupStats.timestamp}</div>
                <div>Images backed up: {backupStats.images_backed_up}</div>
                <div>Labels backed up: {backupStats.labels_backed_up || 0}</div>
                <div>3D print files backed up: {backupStats.print_files_backed_up || 0}</div>
              </div>
            )}
          </div>
        )}
      </div>
      
      <div className="bg-gray-100 rounded-lg p-4 text-sm text-gray-600">
        <p>
          <strong>Note:</strong> The backup process will copy your database, configuration files, product images, product labels, and 3D print files to the GitHub repository.
          This operation requires authentication with a personal access token that has repository write access.
        </p>
      </div>
    </div>
  );
};

export default AdminPage; 