import axios, { AxiosResponse } from 'axios';
import { 
  ThingiverseItem, 
  PaginatedResponse, 
  SavedItem, 
  SearchParams,
  SavedItemsParams,
  ThingiverseFile,
  SavedSearchParams,
  SavedSearch,
  CompetitorUrl
} from '../types';

// Get the API URL from environment variables or use a default
const baseUrl = process.env.REACT_APP_API_URL || '/api';

console.log('API URL:', baseUrl);

// Export the base URL for use with fetch API calls
export const API_BASE_URL = baseUrl;

// Create axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true  // Enable credentials for CORS
});

// Search Thingiverse
export const searchThingiverse = async (params: SearchParams): Promise<PaginatedResponse> => {
  try {
    console.log('Searching with params:', params);
    console.log('Request URL:', `${api.defaults.baseURL}/search`);
    
    const response = await api.get('/search', { params });
    
    // Ensure each item has a title
    const items = response.data.items.map((item: ThingiverseItem) => ({
      ...item,
      title: item.title || `Item ${item.thingiverse_id}`
    }));
    
    return {
      ...response.data,
      items
    };
  } catch (error) {
    console.error('Error searching Thingiverse:', error);
    // Log more details about the error
    if (axios.isAxiosError(error)) {
      console.error('Request URL:', error.config?.url);
      console.error('Response status:', error.response?.status);
      console.error('Response data:', error.response?.data);
    }
    throw error;
  }
};

// Search items (simplified version using fetch)
export const searchItems = async (params: SearchParams): Promise<PaginatedResponse> => {
  const queryParams = new URLSearchParams();
  
  // Add all params to query string
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      queryParams.append(key, value.toString());
    }
  });
  
  // Always set per_page to 100 if not specified
  if (!params.per_page) {
    queryParams.append('per_page', '100');
  }
  
  const response = await fetch(`${API_BASE_URL}/search?${queryParams.toString()}`);
  
  if (!response.ok) {
    throw new Error(`Search request failed with status ${response.status}`);
  }
  
  return await response.json();
};

// Get Thingiverse item details
export const getThingiverseItem = async (itemId: number): Promise<ThingiverseItem> => {
  try {
    const response: AxiosResponse<ThingiverseItem> = await api.get(`/thingiverse/item/${itemId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Thingiverse item:', error);
    throw error;
  }
};

// Get Thingiverse categories
export const getCategories = async (): Promise<any[]> => {
  try {
    const response = await fetch(`${API_BASE_URL}/thingiverse/categories`);
    
    if (!response.ok) {
      throw new Error(`Categories request failed with status ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

// Save an item
export const saveItem = async (item: ThingiverseItem): Promise<SavedItem> => {
  try {
    console.log('Saving item:', item);
    console.log('Request URL:', `${API_BASE_URL}/saved-items`);
    
    const response = await fetch(`${API_BASE_URL}/saved-items`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error(`Failed to save item: ${response.status} ${errorText}`);
    }

    return response.json();
  } catch (error) {
    console.error('Error saving item:', error);
    throw error;
  }
};

// Hide an item
export const hideItem = async (item: ThingiverseItem): Promise<any> => {
  try {
    const response = await api.post('/hidden-items', item);
    return response.data;
  } catch (error) {
    console.error('Error hiding item:', error);
    throw error;
  }
};

// Unhide an item
export const unhideItem = async (thingiverseId: number): Promise<any> => {
  try {
    const response = await api.delete(`/hidden-items/${thingiverseId}`);
    return response.data;
  } catch (error) {
    console.error('Error unhiding item:', error);
    throw error;
  }
};

// Get hidden item IDs
export const getHiddenItemIds = async (): Promise<number[]> => {
  try {
    const response = await api.get('/hidden-items/ids');
    return response.data;
  } catch (error) {
    console.error('Error fetching hidden item IDs:', error);
    throw error;
  }
};

export const getSavedItems = async (params?: SavedItemsParams): Promise<SavedItem[]> => {
  const queryParams = new URLSearchParams();
  
  if (params) {
    if (params.sort_by) queryParams.append('sort_by', params.sort_by);
    if (params.sort_order) queryParams.append('sort_order', params.sort_order);
    if (params.category) queryParams.append('category', params.category);
    if (params.favorites_only) queryParams.append('favorites_only', params.favorites_only.toString());
    if (params.status) queryParams.append('status', params.status);
  }

  const queryString = queryParams.toString();
  const url = `${API_BASE_URL}/saved-items${queryString ? `?${queryString}` : ''}`;
  
  const response = await fetch(url);
  
  if (!response.ok) {
    throw new Error('Failed to fetch saved items');
  }

  return response.json();
};

// Get a specific saved item
export const getSavedItem = async (itemId: number): Promise<SavedItem> => {
  try {
    const response: AxiosResponse<SavedItem> = await api.get(`/saved-items/${itemId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching saved item:', error);
    throw error;
  }
};

// Update a saved item
export const updateSavedItem = async (itemId: number, data: Partial<SavedItem>): Promise<SavedItem> => {
  try {
    console.log('API: Sending update request for item', itemId, 'with data:', data);
    console.log('API: supplier_id in update request:', data.supplier_id);
    
    const response: AxiosResponse<SavedItem> = await api.put(`/saved-items/${itemId}`, data);
    
    console.log('API: Received response from update:', response.data);
    console.log('API: supplier_id in response:', response.data.supplier_id);
    
    return response.data;
  } catch (error) {
    console.error('Error updating saved item:', error);
    throw error;
  }
};

// Delete a saved item
export const deleteSavedItem = async (itemId: number): Promise<{ message: string }> => {
  try {
    const response: AxiosResponse<{ message: string }> = await api.delete(`/saved-items/${itemId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting saved item:', error);
    throw error;
  }
};

// Toggle favorite status
export const toggleFavorite = async (id: number, isFavorite: boolean): Promise<SavedItem> => {
  try {
    const response = await api.patch(`/saved-items/${id}/favorite`, {
      is_favorite: isFavorite
    });
    return response.data;
  } catch (error) {
    console.error('Error toggling favorite status:', error);
    throw error;
  }
};

// Get item details
export const getItemDetails = async (id: number): Promise<ThingiverseItem | SavedItem> => {
  try {
    const response = await api.get(`/items/${id}`);
    
    // Ensure the item has a title
    return {
      ...response.data,
      title: response.data.title || `Item ${response.data.thingiverse_id}`
    };
  } catch (error) {
    console.error('Error fetching item details:', error);
    throw error;
  }
};

// Get Thingiverse file data
export const getThingiverseFiles = async (thingiverseId: number): Promise<ThingiverseFile[]> => {
  try {
    console.log('Fetching Thingiverse files for:', thingiverseId);
    const response: AxiosResponse<ThingiverseFile[]> = await api.get(`/thingiverse/things/${thingiverseId}/files`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Thingiverse files:', error);
    throw error;
  }
};

// Save file data for an item
export const saveItemFiles = async (thingiverseId: number, files: ThingiverseFile[]): Promise<any> => {
  try {
    console.log('Saving file data for item:', thingiverseId);
    const response = await api.post(`/saved-items/${thingiverseId}/files`, files);
    return response.data;
  } catch (error) {
    console.error('Error saving file data:', error);
    throw error;
  }
};

// Get saved file data for an item
export const getSavedItemFiles = async (thingiverseId: number): Promise<ThingiverseFile[]> => {
  try {
    const response: AxiosResponse<ThingiverseFile[]> = await api.get(`/saved-items/${thingiverseId}/files`);
    return response.data;
  } catch (error) {
    console.error('Error fetching saved file data:', error);
    throw error;
  }
};

// Delete a saved file
export const deleteSavedItemFile = async (fileId: number): Promise<{ message: string, file_id: number }> => {
  try {
    const response: AxiosResponse<{ message: string, file_id: number }> = await api.delete(`/saved-items/files/${fileId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting saved file:', error);
    throw error;
  }
};

// Saved Searches API functions
export const getSavedSearches = async (params?: SavedSearchParams): Promise<SavedSearch[]> => {
  const queryParams = new URLSearchParams();
  
  if (params) {
    if (params.sort_by) queryParams.append('sort_by', params.sort_by);
    if (params.sort_order) queryParams.append('sort_order', params.sort_order);
  }

  const queryString = queryParams.toString();
  const url = `${API_BASE_URL}/saved-searches${queryString ? `?${queryString}` : ''}`;
  
  const response = await fetch(url);
  
  if (!response.ok) {
    throw new Error('Failed to fetch saved searches');
  }

  return response.json();
};

export const getSavedSearch = async (searchId: number): Promise<SavedSearch> => {
  try {
    const response = await api.get(`/saved-searches/${searchId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching saved search:', error);
    throw error;
  }
};

export const createSavedSearch = async (searchData: any): Promise<SavedSearch> => {
  try {
    const response = await api.post('/saved-searches', searchData);
    return response.data;
  } catch (error) {
    console.error('Error creating saved search:', error);
    throw error;
  }
};

export const updateSavedSearch = async (searchId: number, searchData: any): Promise<SavedSearch> => {
  try {
    const response = await api.put(`/saved-searches/${searchId}`, searchData);
    return response.data;
  } catch (error) {
    console.error('Error updating saved search:', error);
    throw error;
  }
};

export const deleteSavedSearch = async (searchId: number): Promise<{ message: string }> => {
  try {
    const response = await api.delete(`/saved-searches/${searchId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting saved search:', error);
    throw error;
  }
};

export const executeSavedSearch = async (searchId: number): Promise<any> => {
  try {
    const response = await api.post(`/saved-searches/${searchId}/execute`);
    return response.data;
  } catch (error) {
    console.error('Error executing saved search:', error);
    throw error;
  }
};

// Competitor URL related functions

// Get all competitor URLs for an item
export const getCompetitorUrls = async (itemId: number): Promise<CompetitorUrl[]> => {
  try {
    const response = await fetch(`${API_BASE_URL}/saved-items/${itemId}/competitor-urls`);
    
    if (!response.ok) {
      throw new Error(`Failed to fetch competitor URLs: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error fetching competitor URLs:', error);
    throw error;
  }
};

// Get a specific competitor URL
export const getCompetitorUrl = async (urlId: number): Promise<CompetitorUrl> => {
  try {
    const response = await fetch(`${API_BASE_URL}/saved-items/competitor-urls/${urlId}`);
    
    if (!response.ok) {
      throw new Error(`Failed to fetch competitor URL: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error fetching competitor URL:', error);
    throw error;
  }
};

// Create a new competitor URL
export const createCompetitorUrl = async (itemId: number, urlData: {
  url: string;
  name?: string;
  sales_estimate?: number;
  notes?: string;
}): Promise<CompetitorUrl> => {
  try {
    const response = await fetch(`${API_BASE_URL}/saved-items/${itemId}/competitor-urls`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...urlData,
        item_id: itemId,
      }),
    });
    
    if (!response.ok) {
      throw new Error(`Failed to create competitor URL: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating competitor URL:', error);
    throw error;
  }
};

// Update a competitor URL
export const updateCompetitorUrl = async (urlId: number, urlData: {
  url?: string;
  name?: string;
  sales_estimate?: number;
  notes?: string;
}): Promise<CompetitorUrl> => {
  try {
    const response = await fetch(`${API_BASE_URL}/saved-items/competitor-urls/${urlId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(urlData),
    });
    
    if (!response.ok) {
      throw new Error(`Failed to update competitor URL: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error updating competitor URL:', error);
    throw error;
  }
};

// Delete a competitor URL
export const deleteCompetitorUrl = async (urlId: number): Promise<{ message: string }> => {
  try {
    const response = await fetch(`${API_BASE_URL}/saved-items/competitor-urls/${urlId}`, {
      method: 'DELETE',
    });
    
    if (!response.ok) {
      throw new Error(`Failed to delete competitor URL: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error deleting competitor URL:', error);
    throw error;
  }
}; 