import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { saveItem, hideItem } from '../services/api';
import { ThingiverseItem, SavedItem } from '../types';

interface ProductCardProps {
  item: ThingiverseItem | SavedItem;
  onSave?: (savedItem: SavedItem) => void;
  onHide?: (thingiverseId: number) => void;
  isSavedItem?: boolean;
  isSaved?: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({ 
  item, 
  onSave, 
  onHide,
  isSavedItem = false,
  isSaved = false
}) => {
  const [imageError, setImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Function to get a fallback image URL based on the item title
  const getFallbackImageUrl = (title: string) => {
    return `https://via.placeholder.com/200x150?text=${encodeURIComponent(title)}`;
  };

  // Try different image URL formats if the original fails
  const tryAlternativeImageUrl = (originalUrl: string) => {
    if (originalUrl.includes('_preview_card')) {
      return originalUrl.replace('_preview_card', '');
    } else if (originalUrl.endsWith('.jpg')) {
      return originalUrl.replace('.jpg', '.png');
    } else if (originalUrl.endsWith('.png')) {
      return originalUrl.replace('.png', '.jpg');
    }
    return null;
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const savedItem = await saveItem(item as ThingiverseItem);
      if (onSave) {
        onSave(savedItem);
      }
    } catch (err) {
      console.error('Error saving item:', err);
      setError('Failed to save item');
    } finally {
      setIsLoading(false);
    }
  };

  const handleHide = async () => {
    try {
      setIsLoading(true);
      setError(null);
      await hideItem(item as ThingiverseItem);
      if (onHide) {
        onHide(item.thingiverse_id);
      }
    } catch (err) {
      console.error('Error hiding item:', err);
      setError('Failed to hide item');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const target = e.target as HTMLImageElement;
    const originalSrc = target.src;
    
    // Try alternative URL format
    const alternativeSrc = tryAlternativeImageUrl(originalSrc);
    
    if (alternativeSrc && alternativeSrc !== originalSrc) {
      target.src = alternativeSrc;
    } else {
      // If all attempts fail, show fallback
      setImageError(true);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
      <div className="relative h-48 bg-gray-200">
        {!imageError ? (
          <>
            {isSavedItem ? (
              <Link to={`/item/${(item as SavedItem).id}`}>
                <img
                  src={item.thumbnail_url}
                  alt={item.title}
                  className="w-full h-48 object-cover"
                  onError={handleImageError}
                />
              </Link>
            ) : (
              <img
                src={item.thumbnail_url}
                alt={item.title}
                className="w-full h-48 object-cover"
                onError={handleImageError}
              />
            )}
          </>
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-100 p-4">
            <p className="text-gray-500 text-center">
              <span className="block text-sm font-medium">Image not available</span>
              <span className="block text-xs mt-1">{item.title}</span>
            </p>
          </div>
        )}
      </div>
      
      <div className="p-4">
        {isSavedItem ? (
          <Link to={`/item/${(item as SavedItem).id}`}>
            <h3 className="text-lg font-semibold text-gray-800 mb-2 line-clamp-2 hover:text-blue-600 transition-colors">{item.title}</h3>
          </Link>
        ) : (
          <h3 className="text-lg font-semibold text-gray-800 mb-2 line-clamp-2">{item.title}</h3>
        )}
        
        <div className="flex flex-wrap gap-2 mb-3">
          {item.category && (
            <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
              {item.category}
            </span>
          )}
          {isSavedItem && (item as SavedItem).status && (
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
              (item as SavedItem).status === 'active'
                ? 'bg-green-100 text-green-800'
                : (item as SavedItem).status === 'inactive'
                ? 'bg-gray-100 text-gray-800'
                : 'bg-yellow-100 text-yellow-800'
            }`}>
              {((item as SavedItem).status || 'active').charAt(0).toUpperCase() + ((item as SavedItem).status || 'active').slice(1)}
            </span>
          )}
        </div>
        
        {/* Improved links with icons */}
        <div className="flex flex-col space-y-3 mb-4">
                    
          <a 
            href={item.amazon_search_url} 
            target="_blank" 
            rel="noopener noreferrer"
            className="flex items-center text-blue-600 hover:text-blue-800 transition-colors group"
          >
            <div className="w-8 h-8 flex items-center justify-center bg-yellow-100 rounded-full mr-2 group-hover:bg-yellow-200 transition-colors">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-700" viewBox="0 0 24 24" fill="currentColor">
                <path d="M21.5 11.5c-.17 0-.34.01-.5.03V7c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1v1H8V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4.53c-.16-.02-.33-.03-.5-.03-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5c1.93 0 3.5-1.57 3.5-3.5 0-.95-.38-1.81-1-2.44V9h7v2.56c-.62.63-1 1.49-1 2.44 0 1.93 1.57 3.5 3.5 3.5 1.93 0 3.5-1.57 3.5-3.5 0-.95-.38-1.81-1-2.44V9h2v2.56c-.62.63-1 1.49-1 2.44 0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zM5 7h2v2H5V7zm14 0h2v2h-2V7z"/>
              </svg>
            </div>
            <span>Search on Amazon</span>
          </a>
          
          <a 
            href={item.ebay_search_url} 
            target="_blank" 
            rel="noopener noreferrer"
            className="flex items-center text-blue-600 hover:text-blue-800 transition-colors group"
          >
            <div className="w-8 h-8 flex items-center justify-center bg-red-100 rounded-full mr-2 group-hover:bg-red-200 transition-colors">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-600" viewBox="0 0 24 24" fill="currentColor">
                <path d="M19 6H5c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 10H5V8h14v8z"/>
                <path d="M7 9h2v6H7zm4 0h2v6h-2zm4 0h2v6h-2z"/>
              </svg>
            </div>
            <span>Search on eBay</span>
          </a>

          <a 
            href={item.thingiverse_url} 
            target="_blank" 
            rel="noopener noreferrer"
            className="flex items-center text-blue-600 hover:text-blue-800 transition-colors group"
          >
            <div className="w-8 h-8 flex items-center justify-center bg-blue-100 rounded-full mr-2 group-hover:bg-blue-200 transition-colors">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-600" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12.75 11.25H9c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h3.75c.41 0 .75.34.75.75s-.34.75-.75.75zm3 3H9c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h6.75c.41 0 .75.34.75.75s-.34.75-.75.75z"/>
                <path d="M12 21.75c-5.38 0-9.75-4.37-9.75-9.75S6.62 2.25 12 2.25s9.75 4.37 9.75 9.75-4.37 9.75-9.75 9.75zm0-18c-4.55 0-8.25 3.7-8.25 8.25s3.7 8.25 8.25 8.25 8.25-3.7 8.25-8.25-3.7-8.25-8.25-8.25z"/>
              </svg>
            </div>
            <span>View on Thingiverse</span>
          </a>
        </div>
        
        <div className="mt-4 flex justify-between">
          {!isSavedItem && !isSaved && (
            <button
              onClick={handleSave}
              disabled={isLoading}
              className="flex items-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors disabled:opacity-50"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
              </svg>
              {isLoading ? 'Saving...' : 'Save'}
            </button>
          )}
          
          {!isSavedItem && isSaved && (
            <span className="flex items-center px-4 py-2 bg-gray-200 text-gray-700 rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 text-green-600" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              Saved
            </span>
          )}
          
          {!isSavedItem && (
            <button
              onClick={handleHide}
              disabled={isLoading}
              className="flex items-center px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors disabled:opacity-50"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
              </svg>
              Hide
            </button>
          )}
          
          {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default ProductCard; 