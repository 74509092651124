import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBoxOpen, FaChartLine, FaExclamationTriangle, FaTruck } from "react-icons/fa";

const Navbar: React.FC = () => {
  const location = useLocation();
  
  const navItems = [
    {
      to: "/",
      text: "Products",
      icon: FaBoxOpen,
    },
    {
      to: "/needs-reorder",
      text: "Needs Reorder",
      icon: FaExclamationTriangle,
    },
    {
      to: "/statistics",
      text: "Statistics",
      icon: FaChartLine,
    },
    {
      to: "/suppliers",
      text: "Suppliers",
      icon: FaTruck,
    },
  ];
  
  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-6 py-3">
        <div className="md:flex md:justify-between md:items-center">
          <div className="flex items-center justify-between">
            <div>
              <Link to="/" className="text-gray-800 text-xl font-bold md:text-2xl hover:text-gray-700">
                Dizzle
              </Link>
            </div>
          </div>
          
          <div className="flex flex-col md:flex-row md:items-center">
            {navItems.map((item) => {
              const Icon = item.icon;
              const isActive = location.pathname === item.to || 
                (item.to !== '/' && location.pathname.startsWith(item.to));
              
              return (
                <Link
                  key={item.to}
                  to={item.to}
                  className={`px-4 py-2 mt-2 md:mt-0 md:ml-4 font-medium rounded-lg flex items-center ${
                    isActive 
                      ? 'text-white bg-blue-600' 
                      : 'text-gray-600 hover:text-blue-600 hover:bg-gray-50'
                  }`}
                >
                  <Icon className="mr-2" />
                  {item.text}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 