import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SearchForm from '../components/SearchForm';
import ProductCard from '../components/ProductCard';
import Pagination from '../components/Pagination';
import { searchItems, getSavedItems, getHiddenItemIds, createSavedSearch, getSavedSearches, executeSavedSearch } from '../services/api';
import { ThingiverseItem, SavedItem, SavedSearch } from '../types';

const SearchPage: React.FC = () => {
  const [searchResults, setSearchResults] = useState<ThingiverseItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [savedItemIds, setSavedItemIds] = useState<Set<number>>(new Set());
  const [hiddenItemIds, setHiddenItemIds] = useState<Set<number>>(new Set());
  const [hiddenItemsCount, setHiddenItemsCount] = useState(0);
  const [searchParams, setSearchParams] = useState<any>({
    sort_by: 'popularity',
    sort_order: 'asc'
  });
  const [hasSearched, setHasSearched] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [topSearches, setTopSearches] = useState<SavedSearch[]>([]);
  const [isLoadingSearches, setIsLoadingSearches] = useState(false);
  const pageTopRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Scroll to top when search results change, but only for explicit searches, not for hiding items
  useEffect(() => {
    if (pageTopRef.current && isLoading) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [searchResults, isLoading]);

  // Fetch saved items, hidden items, and top searches when component mounts
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch saved items
        const items = await getSavedItems();
        const ids = new Set(items.map((item: any) => item.thingiverse_id));
        setSavedItemIds(ids);
        
        // Fetch hidden items
        const hiddenIds = await getHiddenItemIds();
        setHiddenItemIds(new Set(hiddenIds));
        setHiddenItemsCount(hiddenIds.length);

        // Fetch top searches
        setIsLoadingSearches(true);
        const searches = await getSavedSearches();
        setTopSearches(searches.slice(0, 10)); // Get top 10 searches
        setIsLoadingSearches(false);
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setIsLoadingSearches(false);
      }
    };

    fetchInitialData();
  }, []);

  // Check for search parameters from navigation state (from saved searches)
  useEffect(() => {
    if (location.state && location.state.searchParams) {
      const params = location.state.searchParams;
      handleSearch(params);
      
      // Clear the location state to avoid repeating the search on page refresh
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  // Clear success message after 3 seconds
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
      
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleSearch = async (params: any) => {
    setIsLoading(true);
    setError(null);
    setSearchParams(params);
    setHasSearched(true);

    try {
      const data = await searchItems({
        ...params,
        page: 1,
        per_page: 100
      });
      
      setSearchResults(data.items);
      setCurrentPage(data.page);
      setTotalPages(data.total_pages);
    } catch (error) {
      console.error('Error searching items:', error);
      setError('An error occurred while searching. Please try again.');
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = async (page: number) => {
    setIsLoading(true);
    setError(null);

    try {
      const data = await searchItems({
        ...searchParams,
        page,
        per_page: 100
      });
      
      setSearchResults(data.items);
      setCurrentPage(data.page);
      setTotalPages(data.total_pages);
    } catch (error) {
      console.error('Error searching items:', error);
      setError('An error occurred while searching. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleItemSaved = (savedItem: SavedItem) => {
    setSavedItemIds(prev => new Set(prev).add(savedItem.thingiverse_id));
    setSuccessMessage('Item saved successfully!');
  };

  const handleItemHidden = (thingiverseId: number) => {
    // Add to hidden items set
    setHiddenItemIds(prev => {
      const newSet = new Set(prev);
      newSet.add(thingiverseId);
      return newSet;
    });
    
    // Increment hidden items count
    setHiddenItemsCount(prev => prev + 1);
    
    // Remove from search results without triggering scroll (setIsLoading remains false)
    setSearchResults(prev => 
      prev.filter(item => item.thingiverse_id !== thingiverseId)
    );
    
    setSuccessMessage('Item hidden successfully!');
  };

  const handleSaveSearch = async () => {
    if (!hasSearched || !searchParams.query) {
      return;
    }
    
    try {
      await createSavedSearch(searchParams);
      setSuccessMessage('Search saved successfully!');
      
      // Refresh top searches
      const searches = await getSavedSearches();
      setTopSearches(searches.slice(0, 10));
    } catch (error) {
      console.error('Error saving search:', error);
      setError('Failed to save search. Please try again.');
    }
  };

  const handleExecuteTopSearch = async (searchId: number) => {
    try {
      const searchParams = await executeSavedSearch(searchId);
      handleSearch(searchParams);
    } catch (error) {
      console.error('Error executing saved search:', error);
      setError('Failed to execute search. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-16" ref={pageTopRef}>
      {/* Main content container */}
      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Top Saved Searches */}
        <div className="mb-6">
          <h2 className="text-lg font-medium text-gray-700 mb-3">Top Searches</h2>
          {isLoadingSearches ? (
            <div className="flex justify-center items-center py-4">
              <div className="w-8 h-8 border-2 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
            </div>
          ) : topSearches.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {topSearches.map(search => (
                <button
                  key={search.id}
                  onClick={() => handleExecuteTopSearch(search.id)}
                  className="px-3 py-1.5 bg-white border border-gray-300 rounded-full text-sm text-gray-700 hover:bg-gray-50 flex items-center space-x-1"
                >
                  <span>{search.query}</span>
                  <span className="text-xs text-gray-500 bg-gray-100 rounded-full px-1.5">{search.search_count}</span>
                </button>
              ))}
            </div>
          ) : (
            <p className="text-sm text-gray-500">No saved searches yet. Save a search to see it here.</p>
          )}
        </div>
        
        {/* Search form */}
        <SearchForm onSearch={handleSearch} initialQuery={searchParams?.query || ''} />
      </div>

      {/* Success message */}
      {successMessage && (
        <div className="max-w-7xl mx-auto px-4 mb-6">
          <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-green-700">{successMessage}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Search results */}
      <div className="max-w-7xl mx-auto px-4 py-8">
        {isLoading && (
          <div className="flex justify-center items-center py-12">
            <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
            {error}
          </div>
        )}

        {!isLoading && !error && hasSearched && searchResults.length > 0 && (
          <>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold">Search Results</h2>
              <button
                onClick={handleSaveSearch}
                className="px-4 py-2 bg-blue-600 text-white font-medium rounded hover:bg-blue-700 transition-colors flex items-center"
              >
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path>
                </svg>
                Save This Search
              </button>
            </div>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {searchResults.map(item => (
                <ProductCard 
                  key={item.thingiverse_id} 
                  item={item}
                  isSaved={savedItemIds.has(item.thingiverse_id)}
                  onSave={handleItemSaved}
                  onHide={handleItemHidden}
                />
              ))}
            </div>

            {totalPages > 1 && (
              <div className="mt-8 flex justify-center">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </>
        )}

        {!isLoading && !error && hasSearched && searchResults.length === 0 && (
          <div className="text-center py-12">
            <h3 className="text-xl font-medium text-gray-600">No results found</h3>
            <p className="text-gray-500 mt-2">Try different search terms or filters</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPage; 