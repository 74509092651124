import React, { useState, useEffect, useCallback } from 'react';
import ProductCard from '../components/ProductCard';
import { getSavedItems, updateSavedItem, deleteSavedItem } from '../services/api';
import { SavedItem, SavedItemsParams } from '../types';

const SavedItemsPage: React.FC = () => {
  const [savedItems, setSavedItems] = useState<SavedItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<string>('saved_at');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [category, setCategory] = useState<string>('');
  const [categories, setCategories] = useState<string[]>([]);
  const [showFavorites, setShowFavorites] = useState<boolean>(false);
  const [status, setStatus] = useState<'active' | 'inactive' | 'inventory'>('active');

  const fetchSavedItems = useCallback(async (params?: SavedItemsParams): Promise<void> => {
    setIsLoading(true);
    setError(null);
    
    try {
      const items = await getSavedItems(params);
      setSavedItems(items);
      
      // Extract unique categories and filter out undefined values
      const uniqueCategories = Array.from(
        new Set(items.map(item => item.category).filter((cat): cat is string => Boolean(cat)))
      );
      setCategories(uniqueCategories);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch saved items');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSavedItems({
      sort_by: sortBy,
      sort_order: sortOrder,
      category: category || undefined,
      favorites_only: showFavorites,
      status
    });
  }, [fetchSavedItems, sortBy, sortOrder, category, showFavorites, status]);

  const handleToggleFavorite = async (item: SavedItem): Promise<void> => {
    try {
      const updatedItem = await updateSavedItem(item.id, {
        is_favorite: !item.is_favorite
      });
      
      setSavedItems(prevItems =>
        prevItems.map(prevItem =>
          prevItem.id === updatedItem.id ? updatedItem : prevItem
        )
      );
    } catch (error) {
      console.error('Error updating favorite status:', error);
    }
  };

  const handleDeleteItem = async (itemId: number): Promise<void> => {
    if (!window.confirm('Are you sure you want to delete this item?')) {
      return;
    }
    
    try {
      await deleteSavedItem(itemId);
      setSavedItems(prevItems => prevItems.filter(item => item.id !== itemId));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6 mt-2">Saved Items</h1>
      
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
          <div>
            <label htmlFor="status" className="block text-gray-700 font-medium mb-2">
              Status
            </label>
            <select
              id="status"
              value={status}
              onChange={(e) => setStatus(e.target.value as 'active' | 'inactive' | 'inventory')}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="active" className="text-green-600">Active</option>
              <option value="inactive" className="text-gray-600">Inactive</option>
              <option value="inventory" className="text-yellow-600">Inventory</option>
            </select>
            <div className="mt-2 flex items-center">
              <div className={`w-3 h-3 rounded-full mr-2 ${
                status === 'inactive' 
                  ? 'bg-gray-500' 
                  : status === 'inventory' 
                  ? 'bg-yellow-500' 
                  : 'bg-green-500'
              }`}></div>
              <span className="text-xs text-gray-600">
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </span>
            </div>
          </div>
          
          <div>
            <label htmlFor="category" className="block text-gray-700 font-medium mb-2">
              Filter by Category
            </label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">All Categories</option>
              {categories.map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </select>
          </div>
          
          <div>
            <label htmlFor="sortBy" className="block text-gray-700 font-medium mb-2">
              Sort By
            </label>
            <select
              id="sortBy"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="saved_at">Date Saved</option>
              <option value="title">Title</option>
              <option value="popularity">Popularity</option>
              <option value="category">Category</option>
              <option value="created_at">Date Created</option>
            </select>
          </div>
          
          <div>
            <label htmlFor="sortOrder" className="block text-gray-700 font-medium mb-2">
              Sort Order
            </label>
            <select
              id="sortOrder"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </select>
          </div>
          
          <div className="flex items-end">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={showFavorites}
                onChange={() => setShowFavorites(!showFavorites)}
                className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <span className="ml-2 text-gray-700">Show Favorites Only</span>
            </label>
          </div>
        </div>
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : error ? (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          <p>{error}</p>
        </div>
      ) : savedItems.length === 0 ? (
        <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded">
          <p>No saved items found. Save some items from the search page!</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {savedItems.map(item => (
            <ProductCard 
              key={item.id} 
              item={item} 
              isSavedItem={true} 
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SavedItemsPage; 