import React, { useState, useEffect, useRef, ChangeEvent, DragEvent, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { inventoryService } from '../services/inventoryService';
import { Product, ProductImage, ProductLabel, ProductPrintFile, Supplier, ProductCreate } from '../types/inventory';
import { formatImageUrl } from '../utils/urlUtils';
import Navbar from '../components/Navbar';
import debounce from 'lodash/debounce';
import { StlViewer } from 'react-stl-viewer';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../utils/cropImage';

interface FormData extends Omit<Product, 'id' | 'created_at' | 'updated_at'> {}

// Define crop area type
interface CropArea {
    x: number;
    y: number;
    width: number;
    height: number;
}

const InventoryDetailsPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState<ProductImage[]>([]);
    const [uploading, setUploading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dropZoneRef = useRef<HTMLDivElement>(null);
    const [formData, setFormData] = useState<FormData>({
        sku: '',
        title: '',
        description: '',
        category: '',
        brand: '',
        asin: '',
        upc: '',
        fnsku: '',
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        packaging_type: '',
        variation_info: {},
        quantity_available: 0,
        quantity_reserved: 0,
        quantity_in_transit: 0,
        quantity_at_fba: 0,
        reorder_point: 0,
        max_stock_level: 0,
        lead_time_days: 0,
        safety_stock: 0,
        purchase_cost: 0,
        amazon_fees: {},
        shipping_cost: 0,
        total_cost: 0,
        selling_price: 0,
        min_profitable_price: 0,
        profit_margin: 0,
        roi: 0,
        supplier_name: '',
        supplier_contact: '',
        min_order_quantity: 0,
        payment_terms: '',
        manufacturer_sku: '',
        warehouse_location: '',
        fba_fulfillment_center: '',
        storage_location: '',
        sales_velocity: 0,
        days_of_inventory: 0,
        stockout_history: {},
        seasonal_trends: {},
        sales_rank: 0,
        customer_rating: 0,
        shipping_carrier: '',
        tracking_numbers: {},
        estimated_arrival_dates: {},
        inbound_shipment_ids: {},
        fba_shipment_status: '',
        date_added: new Date().toISOString(),
        last_restocked: '',
        last_ordered: '',
        expiration_date: '',
        next_reorder_date: '',
        cost_price: 0,
        product_details: {},
    });
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const [currentSupplier, setCurrentSupplier] = useState<Supplier | null>(null);
    const [selectedImage, setSelectedImage] = useState<ProductImage | null>(null);
    const [labels, setLabels] = useState<ProductLabel[]>([]);
    const [uploadingLabels, setUploadingLabels] = useState(false);
    const [isDraggingLabels, setIsDraggingLabels] = useState(false);
    const labelInputRef = useRef<HTMLInputElement>(null);
    const labelDropZoneRef = useRef<HTMLDivElement>(null);
    
    // 3D Print Files state
    const [printFiles, setPrintFiles] = useState<ProductPrintFile[]>([]);
    const [uploadingPrintFiles, setUploadingPrintFiles] = useState(false);
    const [isDraggingPrintFiles, setIsDraggingPrintFiles] = useState(false);
    const [selectedPrintFile, setSelectedPrintFile] = useState<ProductPrintFile | null>(null);
    const [stlPreviewData, setStlPreviewData] = useState<string | null>(null);
    const [loadingPreview, setLoadingPreview] = useState(false);
    const printFileInputRef = useRef<HTMLInputElement>(null);
    const printFileDropZoneRef = useRef<HTMLDivElement>(null);

    // Add this at the beginning of the component to define the model appearance options
    const [modelOptions, setModelOptions] = useState({
        color: "#0066CC",
        rotate: true,
        showAxes: false,
        shadow: 0.5, // Add shadow intensity from 0 to 1
    });

    // Available colors for the palette - updated with grayscale tones
    const colorPalette = [
        "#333333", // Dark Gray
        "#555555", // Medium Dark Gray
        "#777777", // Medium Gray
        "#999999", // Gray
        "#BBBBBB", // Light Gray
        "#DDDDDD", // Very Light Gray
        "#FFFFFF", // White
        "#0066CC", // Blue
        "#CC3300", // Red
        "#009933"  // Green
    ];

    // Update handler to handle color selection from palette and shadow adjustment
    const toggleModelOption = (option: keyof typeof modelOptions, value?: any) => {
        if (option === 'color' && value) {
            // Set color directly from palette selection
            setModelOptions({
                ...modelOptions,
                color: value
            });
        } else if (option === 'shadow' && typeof value === 'number') {
            // Set shadow intensity value
            setModelOptions({
                ...modelOptions,
                shadow: value
            });
        } else {
            // Toggle boolean options
            setModelOptions({
                ...modelOptions,
                [option]: !modelOptions[option]
            });
        }
    };

    // Image cropping states
    const [isCropModalOpen, setIsCropModalOpen] = useState<boolean>(false);
    const [imageToCrop, setImageToCrop] = useState<ProductImage | null>(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropArea | null>(null);
    const [cropAspect, setCropAspect] = useState(4/3);

    useEffect(() => {
        // Fetch data in sequence to ensure all dependencies are available
        const fetchData = async () => {
            setLoading(true);
            try {
                if (id) {
                    // Load suppliers first
                    const suppliersData = await inventoryService.getSuppliers();
                    console.log('🔥 DEBUG: Suppliers loaded:', suppliersData.length);
                    setSuppliers(suppliersData);
                    
                    // Then load the product
                    const product = await inventoryService.getProduct(parseInt(id));
                    console.log('🔥 DEBUG: Product loaded:', product.id);
                    if (product.supplier_id) {
                        console.log('🔥 DEBUG: Product has supplier ID:', product.supplier_id);
                        // Find supplier details in our already loaded suppliers
                        const supplierDetails = suppliersData.find(s => s.id === product.supplier_id);
                        if (supplierDetails) {
                            console.log('🔥 DEBUG: Found supplier details:', supplierDetails);
                            // Set the current supplier
                            setCurrentSupplier(supplierDetails);
                        }
                    }
                    setFormData(product);
                    
                    // Load images
            const productImages = await inventoryService.getProductImages(parseInt(id));
            setImages(productImages);
                    
                    // Load labels
                    try {
                        const productLabels = await inventoryService.getProductLabels(parseInt(id));
                        setLabels(productLabels);
        } catch (err) {
                        console.error('Error loading product labels:', err);
                        // Don't fail the whole load just because labels failed
                    }
                    
                    // Load 3D print files
                    try {
                        const productPrintFiles = await inventoryService.getProductPrintFiles(parseInt(id));
                        setPrintFiles(productPrintFiles);
                    } catch (err) {
                        console.error('Error loading 3D print files:', err);
                        // Don't fail the whole load just because print files failed
                    }
                }
            } catch (err) {
                console.error('Error loading data:', err);
                toast.error('Failed to load product data');
            } finally {
                setLoading(false);
            }
        };
        
        fetchData();
    }, [id]);

    // Add a new effect to update current supplier when either formData.supplier_id or suppliers changes
    useEffect(() => {
        if (suppliers.length > 0) {
            // First try to find by ID if available
            if (formData.supplier_id) {
                console.log('🔥 DEBUG: Updating current supplier by ID:', formData.supplier_id);
                const supplier = suppliers.find(s => s.id === formData.supplier_id);
                if (supplier) {
                    console.log('🔥 DEBUG: Setting current supplier to:', supplier);
                    setCurrentSupplier(supplier);
                    return;
                }
            }
            
            // If no ID or ID not found, try to find by name if available
            if (formData.supplier_name) {
                console.log('🔥 DEBUG: Trying to find supplier by name:', formData.supplier_name);
                const supplier = suppliers.find(s => s.name === formData.supplier_name);
                if (supplier) {
                    console.log('🔥 DEBUG: Found supplier by name:', supplier);
                    setCurrentSupplier(supplier);
                    return;
                }
            }
            
            // If neither ID nor name matching worked, clear current supplier
            console.log('🔥 DEBUG: No supplier found by ID or name, clearing current supplier');
            setCurrentSupplier(null);
        }
    }, [formData.supplier_id, formData.supplier_name, suppliers]);

    const handleImageUpload = async (files: File[]) => {
        if (!id || !files.length) return;
        
        if (files.length + images.length > 10) {
            toast.error('Maximum 10 images allowed');
            return;
        }

        setUploading(true);
        try {
            for (const file of files) {
                const formData = new FormData();
                formData.append('image', file as Blob);
                formData.append('product_id', id);
                formData.append('is_primary', (images.length === 0).toString());
                formData.append('order', images.length.toString());
                
                await inventoryService.uploadProductImage(parseInt(id), formData);
            }
            // Load images directly instead of calling the removed function
            const updatedImages = await inventoryService.getProductImages(parseInt(id));
            setImages(updatedImages);
            
            toast.success('Images uploaded successfully');
        } catch (err) {
            toast.error('Failed to upload images');
        } finally {
            setUploading(false);
        }
    };

    const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const files = Array.from(event.target.files) as File[];
        handleImageUpload(files);
    };

    const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isDragging) {
            setIsDragging(true);
        }
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const files = Array.from(e.dataTransfer.files) as File[];
            // Filter only image files
            const imageFiles = files.filter(file => file.type.startsWith('image/'));
            handleImageUpload(imageFiles);
        }
    };

    const openFileDialog = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleSetPrimaryImage = async (imageId: number) => {
        if (!id) return;
        console.log("Setting primary image:", imageId, "for product:", id);
        try {
            console.log("Making API call to set primary image");
            // Format the product ID as a number and verify the image ID
            const productId = parseInt(id);
            console.log("Formatted product ID:", productId, "image ID:", imageId);
            console.log("API endpoint:", `${process.env.REACT_APP_API_URL || '/api'}/v1/inventory/products/${productId}/images/${imageId}/set-primary/`);
            
            const response = await inventoryService.setPrimaryImage(productId, imageId);
            console.log("API response:", response);
            
            if (response) {
                // Load images directly instead of calling the removed function
                const updatedImages = await inventoryService.getProductImages(productId);
                console.log("Updated images:", updatedImages);
                setImages(updatedImages);
                
            toast.success('Primary image updated');
            } else {
                console.error("No response received from setPrimaryImage API call");
                toast.error('No response from server');
            }
        } catch (err) {
            console.error("Error setting primary image:", err);
            toast.error('Failed to update primary image');
        }
    };

    const handleDeleteImage = async (imageId: number) => {
        if (!id) return;
        try {
            await inventoryService.deleteProductImage(parseInt(id), imageId);
            
            // Load images directly instead of calling the removed function
            const updatedImages = await inventoryService.getProductImages(parseInt(id));
            setImages(updatedImages);
            
            toast.success('Image deleted successfully');
        } catch (err) {
            toast.error('Failed to delete image');
        }
    };

    const handlePrimaryClick = (e: React.MouseEvent<HTMLButtonElement>, imageId: number): void => {
        e.stopPropagation();
        e.preventDefault();
        console.log("Primary button clicked for image:", imageId);
        handleSetPrimaryImage(imageId);
    };
    
    const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>, imageId: number): void => {
        e.stopPropagation();
        e.preventDefault();
        console.log("Delete button clicked for image:", imageId);
        handleDeleteImage(imageId);
    };

    const saveChanges = async (data: FormData) => {
        if (!id) return;
        try {
            console.log('🔥 Saving changes to product ID:', id);
            console.log('🔥 Data being sent to server:', data);
            
            // Create a copy of the data to modify
            const formattedData: any = { ...data };
            
            // Format date fields if they exist
            if (formattedData.date_added && typeof formattedData.date_added === 'string') {
                if (!formattedData.date_added.includes('T')) {
                    formattedData.date_added = new Date(formattedData.date_added + 'T00:00:00Z').toISOString();
                }
            }
            
            // Handle optional date fields
            ['last_restocked', 'last_ordered', 'expiration_date', 'next_reorder_date'].forEach(field => {
                if (formattedData[field] && typeof formattedData[field] === 'string') {
                    const dateStr = formattedData[field];
                    if (!dateStr.includes('T') && dateStr.trim() !== '') {
                        formattedData[field] = new Date(dateStr + 'T00:00:00Z').toISOString();
                    }
                }
            });
            
            // Ensure numeric fields are properly formatted
            ['purchase_cost', 'cost_price', 'selling_price', 'shipping_cost', 'total_cost',
             'min_profitable_price', 'profit_margin', 'roi', 'quantity_available',
             'quantity_reserved', 'quantity_in_transit', 'quantity_at_fba', 'reorder_point',
             'max_stock_level', 'lead_time_days', 'safety_stock', 'min_order_quantity',
             'sales_velocity', 'days_of_inventory', 'sales_rank', 'customer_rating',
             'length', 'width', 'height', 'weight'].forEach(field => {
                if (formattedData[field] === '') {
                    formattedData[field] = null;
                } else if (formattedData[field] !== null && formattedData[field] !== undefined) {
                    const value = parseFloat(formattedData[field]);
                    formattedData[field] = isNaN(value) ? null : value;
                }
            });
            
            console.log('🔥 Data after formatting:', formattedData);
            const response = await inventoryService.updateProduct(parseInt(id), formattedData);
            console.log('🔥 API response:', response);
            
            // Update form data with the response to ensure we have the latest values
            setFormData(response);
            
            // Show toast notification only after successful save
            toast.success('Changes saved', {
                id: 'save-toast', // Use the same ID for all save toasts
                duration: 2000    // Show for 2 seconds
            });
            
            return response;
        } catch (err) {
            console.error('🔥 Error saving changes:', err);
            toast.error('Failed to save changes');
            throw err;
        }
    };

    // Replace timer-based saving with immediate save on change
    // Use a timer ID ref for delayed saving
    const saveTimerRef = useRef<number | null>(null);

    const handleInputChange = async (field: keyof FormData, value: any) => {
        // Update local state first for immediate UI feedback
        setFormData((prev: FormData) => {
            const newData = { ...prev, [field]: value };
            return newData;
        });
            
        // Schedule the save - but use a shorter timeout to feel more responsive
            // Clear the previous timer
            if (saveTimerRef.current) {
                clearTimeout(saveTimerRef.current);
            }
            
        // Set a new timer for 1 second (reduced from 5 seconds)
            saveTimerRef.current = window.setTimeout(() => {
            // Get the latest form data and save it
            saveChanges({...formData, [field]: value});
        }, 1000);
    };

    // This is a helper to fix TypeScript complaints about event handler types
    const handleFormInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Clear timer on unmount
    useEffect(() => {
        return () => {
            if (saveTimerRef.current) {
                clearTimeout(saveTimerRef.current);
            }
        };
    }, []);

    // Function to handle clicking on an image to show in modal
    const openImageModal = (image: ProductImage) => {
        setSelectedImage(image);
    };
    
    // Function to close modal
    const closeImageModal = () => {
        setSelectedImage(null);
    };

    // Image cropping functions
    const showCropModal = (image: ProductImage) => {
        setImageToCrop(image);
        setIsCropModalOpen(true);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setRotation(0);
    };

    const closeCropModal = () => {
        setIsCropModalOpen(false);
        setImageToCrop(null);
    };

    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: CropArea) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const createCroppedImage = async () => {
        if (!imageToCrop || !croppedAreaPixels || !id) return;
        
        try {
            toast.loading('Processing image...', { id: 'crop-processing' });
            
            // Use the improved getCroppedImg function to handle the cropping
            const croppedBlob = await getCroppedImg(
                formatImageUrl(imageToCrop.url),
                croppedAreaPixels,
                rotation
            );
            
            // Create a filename with the same base as the original but noting it's cropped
            const origFilename = imageToCrop.filename || 'cropped-image.jpg';
            const extension = origFilename.split('.').pop();
            const baseFilename = origFilename.replace(`.${extension}`, '');
            const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
            const filename = `${baseFilename}-cropped-${timestamp}.${extension}`;
            
            // Create a file from the blob
            const file = new File([croppedBlob], filename, { 
                type: `image/${extension === 'png' ? 'png' : 'jpeg'}` 
            });
            
            // Upload the cropped image
            const formData = new FormData();
            formData.append('image', file);
            formData.append('product_id', id);
            formData.append('is_primary', 'false');
            formData.append('order', images.length.toString());
            
            // Upload and refresh images
            await inventoryService.uploadProductImage(parseInt(id), formData);
            const updatedImages = await inventoryService.getProductImages(parseInt(id));
            setImages(updatedImages);
            
            toast.success('Cropped image uploaded successfully', { id: 'crop-processing' });
            closeCropModal();
            
        } catch (err) {
            console.error('Error creating cropped image:', err);
            toast.error('Failed to create cropped image', { id: 'crop-processing' });
        }
    };

    // Product Label handling functions
    const handleLabelUpload = async (files: File[]) => {
        if (!id || !files.length) return;
        
        setUploadingLabels(true);
        try {
            for (const file of files) {
                // Check if the file type is PDF or DOCX
                if (!file.type.includes('pdf') && !file.name.endsWith('.docx') && !file.type.includes('word')) {
                    toast.error(`File ${file.name} is not a supported format (PDF/DOCX)`);
                    continue;
                }
                
                const formData = new FormData();
                formData.append('label', file as Blob);
                formData.append('product_id', id);
                formData.append('file_type', file.type.includes('pdf') ? 'pdf' : 'docx');
                formData.append('description', file.name);
                
                await inventoryService.uploadProductLabel(parseInt(id), formData);
            }
            
            // Refresh labels list
            const updatedLabels = await inventoryService.getProductLabels(parseInt(id));
            setLabels(updatedLabels);
            
            toast.success('Labels uploaded successfully');
        } catch (err) {
            console.error('Error uploading labels:', err);
            toast.error('Failed to upload labels');
        } finally {
            setUploadingLabels(false);
        }
    };

    const handleLabelInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const files = Array.from(event.target.files) as File[];
        handleLabelUpload(files);
    };

    const handleLabelDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingLabels(true);
    };

    const handleLabelDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingLabels(false);
    };

    const handleLabelDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isDraggingLabels) {
            setIsDraggingLabels(true);
        }
    };

    const handleLabelDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingLabels(false);
        
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const files = Array.from(e.dataTransfer.files) as File[];
            handleLabelUpload(files);
        }
    };

    const openLabelFileDialog = () => {
        if (labelInputRef.current) {
            labelInputRef.current.click();
        }
    };

    const handleDeleteLabel = async (labelId: number) => {
        if (!id) return;
        try {
            await inventoryService.deleteProductLabel(parseInt(id), labelId);
            
            // Refresh labels list
            const updatedLabels = await inventoryService.getProductLabels(parseInt(id));
            setLabels(updatedLabels);
            
            toast.success('Label deleted successfully');
        } catch (err) {
            console.error('Error deleting label:', err);
            toast.error('Failed to delete label');
        }
    };

    const handleDownloadLabel = async (label: ProductLabel) => {
        if (!id) return;
        try {
            toast.loading('Downloading label...', { id: 'download-label' });
            
            const blob = await inventoryService.downloadProductLabel(parseInt(id), label.id);
            
            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);
            
            // Create a temporary anchor element and trigger download
            const a = document.createElement('a');
            a.href = url;
            a.download = label.filename;
            document.body.appendChild(a);
            a.click();
            
            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            
            toast.success('Label downloaded', { id: 'download-label' });
        } catch (err) {
            console.error('Error downloading label:', err);
            toast.error('Failed to download label', { id: 'download-label' });
        }
    };

    const handlePrintLabel = async (label: ProductLabel) => {
        if (!id) return;
        try {
            if (label.file_type === 'pdf') {
                toast.loading('Preparing PDF for printing...', { id: 'print-label' });
                
                // Get the document content
                const blob = await inventoryService.downloadProductLabel(parseInt(id), label.id);
                
                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);
                
                // Open the document in a new tab/window
                const printWindow = window.open(url, '_blank');
                
                // Wait for the window to load and then trigger print dialog
                if (printWindow) {
                    printWindow.addEventListener('load', () => {
                        // Once document is loaded, open print dialog
                        printWindow.print();
                        // Clean up URL after a delay (to ensure printing has started)
                        setTimeout(() => {
                            window.URL.revokeObjectURL(url);
                        }, 1000);
                    });
                    
                    toast.success('Print dialog opened', { id: 'print-label' });
        } else {
                    // If popup was blocked
                    window.URL.revokeObjectURL(url);
                    toast.error('Could not open print dialog. Please check your popup settings.', { id: 'print-label' });
                }
            } else if (label.file_type === 'docx') {
                toast.loading('Converting Word document to PDF for printing...', { id: 'print-label' });
                
                try {
                    // Convert DOCX to PDF on the server
                    const pdfBlob = await inventoryService.convertLabelToPdf(parseInt(id), label.id);
                    
                    // Create a URL for the PDF blob
                    const pdfUrl = window.URL.createObjectURL(pdfBlob);
                    
                    // Open the PDF in a new tab
                    const printWindow = window.open(pdfUrl, '_blank');
                    
                    // Wait for the window to load and then trigger print dialog
                    if (printWindow) {
                        printWindow.addEventListener('load', () => {
                            // Once PDF is loaded, open print dialog
                            printWindow.print();
                            // Clean up URL after a delay
                            setTimeout(() => {
                                window.URL.revokeObjectURL(pdfUrl);
                            }, 1000);
                        });
                        
                        toast.success('Print dialog opened', { id: 'print-label' });
                    } else {
                        // If popup was blocked
                        window.URL.revokeObjectURL(pdfUrl);
                        toast.error('Could not open print dialog. Please check your popup settings.', { id: 'print-label' });
                    }
                } catch (error) {
                    console.error('Error converting document:', error);
                    toast.error('Failed to convert document for printing. Downloading original instead.', { id: 'print-label' });
                    
                    // Fallback - download the original DOCX
                    const blob = await inventoryService.downloadProductLabel(parseInt(id), label.id);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = label.filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }
            }
        } catch (err) {
            console.error('Error preparing document for printing:', err);
            toast.error('Failed to prepare document for printing', { id: 'print-label' });
        }
    };

    const handleViewLabel = async (label: ProductLabel) => {
        if (!id) return;
        try {
            const blob = await inventoryService.downloadProductLabel(parseInt(id), label.id);
            
            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);
            
            // Open the document in a new tab
            window.open(url, '_blank');
            
            // Clean up after a short delay to allow the new tab to load the document
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 100);
        } catch (err) {
            console.error('Error viewing label:', err);
            toast.error('Failed to view label');
        }
    };

    // 3D Print Files handling functions
    const handlePrintFileUpload = async (files: File[]) => {
        if (!id || !files.length) return;
        
        setUploadingPrintFiles(true);
        try {
            for (const file of files) {
                // Check if the file type is supported
                const fileExtension = file.name.split('.').pop()?.toLowerCase();
                const validExtensions = ['stl', 'obj', 'amf', '3mf', 'gcode'];
                
                if (!fileExtension || !validExtensions.includes(fileExtension)) {
                    toast.error(`File ${file.name} is not a supported format (STL, OBJ, AMF, 3MF, GCODE)`);
                    continue;
                }
                
                const formData = new FormData();
                formData.append('print_file', file as Blob);
                formData.append('product_id', id);
                formData.append('file_type', fileExtension);
                formData.append('description', file.name);
                
                await inventoryService.uploadProductPrintFile(parseInt(id), formData);
            }
            
            // Refresh print files list
            const updatedPrintFiles = await inventoryService.getProductPrintFiles(parseInt(id));
            setPrintFiles(updatedPrintFiles);
            
            toast.success('3D print files uploaded successfully');
        } catch (err) {
            console.error('Error uploading 3D print files:', err);
            toast.error('Failed to upload 3D print files');
        } finally {
            setUploadingPrintFiles(false);
        }
    };

    const handlePrintFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const files = Array.from(event.target.files) as File[];
        handlePrintFileUpload(files);
    };

    const handlePrintFileDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingPrintFiles(true);
    };

    const handlePrintFileDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingPrintFiles(false);
    };

    const handlePrintFileDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isDraggingPrintFiles) {
            setIsDraggingPrintFiles(true);
        }
    };

    const handlePrintFileDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingPrintFiles(false);
        
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const files = Array.from(e.dataTransfer.files) as File[];
            handlePrintFileUpload(files);
        }
    };

    const openPrintFileDialog = () => {
        if (printFileInputRef.current) {
            printFileInputRef.current.click();
        }
    };

    const handleDeletePrintFile = async (printFileId: number) => {
        if (!id) return;
        try {
            await inventoryService.deleteProductPrintFile(parseInt(id), printFileId);
            
            // Refresh print files list
            const updatedPrintFiles = await inventoryService.getProductPrintFiles(parseInt(id));
            setPrintFiles(updatedPrintFiles);
            
            toast.success('3D print file deleted successfully');
        } catch (err) {
            console.error('Error deleting 3D print file:', err);
            toast.error('Failed to delete 3D print file');
        }
    };

    const handleDownloadPrintFile = async (printFile: ProductPrintFile) => {
        if (!id) return;
        try {
            toast.loading('Downloading 3D print file...', { id: 'download-print-file' });
            console.log(`Initiating download for print file: ${printFile.id}`);
            
            const blob = await inventoryService.downloadProductPrintFile(parseInt(id), printFile.id);
            console.log(`Received blob of size: ${blob.size} bytes, type: ${blob.type}`);
            
            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);
            console.log('Created blob URL:', url);
            
            // Create a temporary anchor element and trigger download
            const a = document.createElement('a');
            a.href = url;
            a.download = printFile.filename;
            document.body.appendChild(a);
            console.log('Download link created, clicking to download');
            a.click();
            
            // Clean up
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                console.log('Download resources cleaned up');
            }, 100);
            
            toast.success('3D print file downloaded', { id: 'download-print-file' });
        } catch (err) {
            console.error('Error downloading 3D print file:', err);
            toast.error('Failed to download 3D print file. Check console for details.', { id: 'download-print-file' });
        }
    };
    
    const openPrintFilePreview = async (printFile: ProductPrintFile) => {
        if (!id) return;
        
        setSelectedPrintFile(printFile);
        
        // Only fetch preview data for STL files
        if (printFile.file_type.toLowerCase() === 'stl') {
            setLoadingPreview(true);
            try {
                console.log('Fetching STL preview for file ID:', printFile.id);
                const previewData = await inventoryService.getProductPrintFilePreview(parseInt(id), printFile.id);
                console.log('Preview data received:', previewData ? 'Data found' : 'No data');
                
                if (previewData && previewData.file_data) {
                    console.log('Setting STL preview data, length:', previewData.file_data.length);
                    setStlPreviewData(previewData.file_data);
                } else {
                    console.error('No preview data available');
                    toast.error('Could not load 3D preview data');
                }
            } catch (err) {
                console.error('Error loading STL preview:', err);
                toast.error('Failed to load 3D model preview');
            } finally {
                setLoadingPreview(false);
            }
        }
    };
    
    const closePrintFilePreview = () => {
        setSelectedPrintFile(null);
        setStlPreviewData(null);
    };

    const debouncedSearch = useCallback(
        debounce((searchTerm: string) => {
            // Update to use setSuppliers instead of searchSuppliers
            // This is a placeholder - in a real app you would fetch suppliers based on search term
            console.log("Searching suppliers with term:", searchTerm);
            // Example implementation:
            // const filtered = allSuppliers.filter(s => 
            //   s.name.toLowerCase().includes(searchTerm.toLowerCase())
            // );
            // setSuppliers(filtered);
        }, 300),
        []
    );

    const handleSupplierChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const supplierValue = e.target.value;
        // ... existing code ...
    };

    const addSupplier = (supplier: Supplier) => {
        // ... existing code ...
    };

    // Add function to capture STL viewer as image
    const captureModelSnapshot = async () => {
        if (!id || !stlPreviewData || !selectedPrintFile) return;
        
        try {
            // Get the canvas element
            const canvas = document.querySelector('canvas');
            if (!canvas) {
                toast.error('Could not capture model view');
                return;
            }
            
            // Create a high-resolution offscreen canvas
            const offscreenCanvas = document.createElement('canvas');
            offscreenCanvas.width = 2000;
            offscreenCanvas.height = 2000;
            const ctx = offscreenCanvas.getContext('2d');
            
            if (!ctx) {
                toast.error('Could not create image context');
                return;
            }
            
            // Set white background
            ctx.fillStyle = '#FFFFFF';
            ctx.fillRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
            
            // Draw original canvas content to offscreen canvas, centered and scaled
            const originalImage = new Image();
            originalImage.onload = async () => {
                // Calculate dimensions to maintain aspect ratio and center the model
                const aspectRatio = originalImage.width / originalImage.height;
                let drawWidth, drawHeight, offsetX, offsetY;
                
                if (aspectRatio > 1) {
                    // Image is wider than tall
                    drawWidth = 1800; // Leave some margin
                    drawHeight = drawWidth / aspectRatio;
                    offsetX = (offscreenCanvas.width - drawWidth) / 2;
                    offsetY = (offscreenCanvas.height - drawHeight) / 2;
                } else {
                    // Image is taller than wide
                    drawHeight = 1800; // Leave some margin
                    drawWidth = drawHeight * aspectRatio;
                    offsetX = (offscreenCanvas.width - drawWidth) / 2;
                    offsetY = (offscreenCanvas.height - drawHeight) / 2;
                }
                
                // Draw the centered, scaled image
                ctx.drawImage(originalImage, offsetX, offsetY, drawWidth, drawHeight);
                
                // Convert to blob with high quality
                const blob = await new Promise<Blob | null>((resolve) => 
                    offscreenCanvas.toBlob(resolve, 'image/png', 1.0)
                );
                
                if (!blob) {
                    toast.error('Failed to create image');
                    return;
                }
                
                // Create a timestamp for the filename
                const now = new Date();
                const timestamp = `${now.getFullYear()}${(now.getMonth()+1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`;
                
                // Create a File object from the blob with timestamp in filename
                const file = new File(
                    [blob], 
                    `${selectedPrintFile.filename.split('.')[0]}_3D_view_${timestamp}.png`, 
                    { type: 'image/png' }
                );
                
                // Upload the captured image as a product image
                await handleImageUpload([file]);
                toast.success(`New snapshot saved: ${file.name}`);
            };
            
            // Set source to current canvas
            originalImage.src = canvas.toDataURL('image/png');
            
        } catch (err) {
            console.error('Error capturing model view:', err);
            toast.error('Failed to save model snapshot');
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    return (
        <>
            <Navbar />
            {/* Image Modal */}
            {selectedImage && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80" onClick={closeImageModal}>
                    <div className="relative max-w-4xl max-h-[90vh] overflow-hidden">
                        <img 
                            src={formatImageUrl(selectedImage.url)} 
                            alt={selectedImage.filename}
                            className="max-w-full max-h-[90vh] object-contain"
                        />
                        <button 
                            className="absolute top-3 right-3 bg-red-500 text-white rounded-full p-2 hover:bg-red-600 transition-colors"
                            onClick={(e) => { e.stopPropagation(); closeImageModal(); }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {selectedImage.is_primary && (
                            <div className="absolute top-3 left-3 bg-green-500 text-white px-3 py-1 rounded-full text-sm font-bold">
                                PRIMARY
                            </div>
                        )}
                    </div>
                </div>
            )}
            
            {/* Image Crop Modal */}
            {isCropModalOpen && imageToCrop && (
                <div className="fixed inset-0 z-50 flex flex-col bg-black bg-opacity-90 overflow-hidden">
                    <div className="bg-white p-4 flex justify-between items-center">
                        <h3 className="text-xl font-semibold text-gray-800">Crop Image</h3>
                        <div className="flex space-x-2">
                            <button 
                                onClick={() => setCropAspect(1)}
                                className={`px-3 py-1 rounded text-sm ${cropAspect === 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                            >
                                1:1
                            </button>
                            <button 
                                onClick={() => setCropAspect(4/3)}
                                className={`px-3 py-1 rounded text-sm ${cropAspect === 4/3 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                            >
                                4:3
                            </button>
                            <button 
                                onClick={() => setCropAspect(16/9)}
                                className={`px-3 py-1 rounded text-sm ${cropAspect === 16/9 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                            >
                                16:9
                            </button>
                            <button 
                                onClick={() => setCropAspect(null as any)}
                                className={`px-3 py-1 rounded text-sm ${cropAspect === null ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                            >
                                Free
                            </button>
                        </div>
                        <button 
                            onClick={closeCropModal}
                            className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="flex-grow relative">
                        <Cropper
                            image={formatImageUrl(imageToCrop.url)}
                            crop={crop}
                            zoom={zoom}
                            aspect={cropAspect}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            onRotationChange={setRotation}
                            rotation={rotation}
                        />
                    </div>
                    <div className="bg-white p-4 border-t">
                        <div className="flex flex-col space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">Zoom</label>
                                <input
                                    type="range"
                                    min="1"
                                    max="3"
                                    step="0.1"
                                    value={zoom}
                                    onChange={(e) => setZoom(parseFloat(e.target.value))}
                                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">Rotation</label>
                                <input
                                    type="range"
                                    min="0"
                                    max="360"
                                    step="1"
                                    value={rotation}
                                    onChange={(e) => setRotation(parseFloat(e.target.value))}
                                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                />
                            </div>
                            <div className="flex justify-end">
                                <button
                                    onClick={createCroppedImage}
                                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                                >
                                    Apply Crop
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            
            <div className="container mx-auto px-4 py-8">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-2xl font-bold">Product Details</h1>
                    <button
                        onClick={() => navigate('/inventory')}
                        className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
                    >
                        Back to Inventory
                    </button>
                </div>

                <div className="bg-white shadow-md rounded-lg p-6">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {/* Basic Information */}
                        <div className="space-y-6">
                            <h2 className="text-xl font-semibold border-b pb-2">Basic Information</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">SKU</label>
                                    <input
                                        type="text"
                                        name="sku"
                                        value={formData.sku}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Title</label>
                                    <input
                                        type="text"
                                        name="title"
                                        value={formData.title}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Category</label>
                                    <input
                                        type="text"
                                        name="category"
                                        value={formData.category}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Brand</label>
                                    <input
                                        type="text"
                                        name="brand"
                                        value={formData.brand}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">ASIN</label>
                                    <input
                                        type="text"
                                        name="asin"
                                        value={formData.asin}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">UPC</label>
                                    <input
                                        type="text"
                                        name="upc"
                                        value={formData.upc}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">FNSKU</label>
                                    <input
                                        type="text"
                                        name="fnsku"
                                        value={formData.fnsku}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Description</label>
                                <textarea
                                    name="description"
                                    value={formData.description}
                                    onChange={handleFormInputChange}
                                    rows={3}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                />
                            </div>
                        </div>

                        {/* Physical Details */}
                        <div className="space-y-6">
                            <h2 className="text-xl font-semibold border-b pb-2">Physical Details</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Length (cm)</label>
                                    <input
                                        type="number"
                                        name="length"
                                        step="0.1"
                                        value={formData.length}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Width (cm)</label>
                                    <input
                                        type="number"
                                        name="width"
                                        step="0.1"
                                        value={formData.width}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Height (cm)</label>
                                    <input
                                        type="number"
                                        name="height"
                                        step="0.1"
                                        value={formData.height}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Weight (oz)</label>
                                    <input
                                        type="number"
                                        name="weight"
                                        step="0.1"
                                        value={formData.weight}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Packaging Type</label>
                                    <input
                                        type="text"
                                        name="packaging_type"
                                        value={formData.packaging_type}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Inventory Tracking */}
                        <div className="space-y-6">
                            <h2 className="text-xl font-semibold border-b pb-2">Inventory Tracking</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Available</label>
                                    <input
                                        type="number"
                                        name="quantity_available"
                                        value={formData.quantity_available}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Reserved</label>
                                    <input
                                        type="number"
                                        name="quantity_reserved"
                                        value={formData.quantity_reserved}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">In Transit</label>
                                    <input
                                        type="number"
                                        name="quantity_in_transit"
                                        value={formData.quantity_in_transit}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">FBA</label>
                                    <input
                                        type="number"
                                        name="quantity_at_fba"
                                        value={formData.quantity_at_fba}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Reorder Point</label>
                                    <input
                                        type="number"
                                        name="reorder_point"
                                        value={formData.reorder_point}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Max Stock Level</label>
                                    <input
                                        type="number"
                                        name="max_stock_level"
                                        value={formData.max_stock_level}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Lead Time (days)</label>
                                    <input
                                        type="number"
                                        name="lead_time_days"
                                        value={formData.lead_time_days}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Safety Stock</label>
                                    <input
                                        type="number"
                                        name="safety_stock"
                                        value={formData.safety_stock}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Cost & Pricing */}
                        <div className="space-y-6">
                            <h2 className="text-xl font-semibold border-b pb-2">Cost & Pricing</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Purchase Cost</label>
                                    <input
                                        type="number"
                                        name="purchase_cost"
                                        step="0.01"
                                        value={formData.purchase_cost}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Selling Price</label>
                                    <input
                                        type="number"
                                        name="selling_price"
                                        step="0.01"
                                        value={formData.selling_price}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Min Profitable Price</label>
                                    <input
                                        type="number"
                                        name="min_profitable_price"
                                        step="0.01"
                                        value={formData.min_profitable_price}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Shipping Cost</label>
                                    <input
                                        type="number"
                                        name="shipping_cost"
                                        step="0.01"
                                        value={formData.shipping_cost}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Total Cost</label>
                                    <input
                                        type="number"
                                        name="total_cost"
                                        step="0.01"
                                        value={formData.total_cost}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Profit Margin (%)</label>
                                    <input
                                        type="number"
                                        name="profit_margin"
                                        step="0.01"
                                        value={formData.profit_margin}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">ROI (%)</label>
                                    <input
                                        type="number"
                                        name="roi"
                                        step="0.01"
                                        value={formData.roi}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Supplier Information */}
                        <div className="space-y-6">
                            <h2 className="text-xl font-semibold border-b pb-2">Supplier Information</h2>
                            
                            {/* Improved supplier dropdown to ensure changes persist */}
                            <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mb-4">
                                <div>
                                    <label htmlFor="supplier-select" className="block text-sm font-medium text-gray-700 mb-2">
                                        Supplier
                                    </label>
                                    
                                    {/* Improved supplier dropdown to ensure changes persist */}
                                    <div className="flex items-center">
                                    <select
                                            id="supplier-select"
                                        name="supplier_id"
                                            className="w-full p-2 border border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                                        value={formData.supplier_id || ''}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const supplierId = value ? parseInt(value, 10) : null;
                                                
                                                console.log(`Supplier selected: ${value}, parsed as ID: ${supplierId}`);
                                                
                                                // Create new form data with updated supplier fields
                                                const updatedForm = {...formData, supplier_id: supplierId};
                                                
                                                // If supplier ID exists, add the supplier details
                                                if (supplierId) {
                                                    const supplier = suppliers.find(s => s.id === supplierId);
                                                    if (supplier) {
                                                        // Include all supplier fields needed for the backend
                                                        updatedForm.supplier_name = supplier.name;
                                                        updatedForm.supplier_contact = supplier.contact || '';
                                                        updatedForm.payment_terms = supplier.payment_terms || '';
                                                        updatedForm.min_order_quantity = supplier.min_order_quantity || undefined;
                                                        
                                                        // Set currentSupplier to show lead time info immediately
                                                        setCurrentSupplier(supplier);
                                                    }
                                                } else {
                                                    // Clear supplier fields if no supplier selected
                                                    updatedForm.supplier_name = '';
                                                    updatedForm.supplier_contact = '';
                                                    updatedForm.payment_terms = '';
                                                    updatedForm.min_order_quantity = undefined;
                                                    setCurrentSupplier(null);
                                                }
                                                
                                                // Update form data state
                                                setFormData(updatedForm);
                                                
                                                // Save changes immediately to ensure persistence
                                                saveChanges(updatedForm);
                                                toast.success(supplierId ? 'Supplier updated' : 'Supplier removed');
                                            }}
                                        >
                                            <option value="">-- Select a supplier --</option>
                                            {suppliers.map(supplier => (
                                            <option key={supplier.id} value={supplier.id}>
                                                {supplier.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                    {/* Display current supplier info with enhanced visual feedback */}
                                    {formData.supplier_name && (
                                        <div className="mt-3 text-sm">
                                            <div className="font-medium">
                                                Current Supplier: {currentSupplier ? (
                                                    <Link 
                                                        to={`/suppliers/${currentSupplier.id}`}
                                                        className="text-blue-600 hover:text-blue-800 hover:underline"
                                                    >
                                                        {formData.supplier_name}
                                                    </Link>
                                                ) : (
                                                    formData.supplier_name
                                                )}
                            </div>

                                            {/* Lead time information with improved display */}
                                            <div className="mt-2 p-3 bg-blue-50 rounded-lg">
                                                <div className="font-medium text-blue-700">Lead Time Information:</div>
                                                {currentSupplier ? (
                                                    <>
                                                        <div>Production: {currentSupplier.production_lead_time ? `${currentSupplier.production_lead_time} days` : 'Not specified'}</div>
                                                        <div>Delivery: {currentSupplier.delivery_time ? `${currentSupplier.delivery_time} days` : 'Not specified'}</div>
                                                        <div>Total Lead Time: {
                                                            (currentSupplier.production_lead_time || 0) + (currentSupplier.delivery_time || 0) > 0 
                                                                ? `${(currentSupplier.production_lead_time || 0) + (currentSupplier.delivery_time || 0)} days` 
                                                                : 'Not specified'
                                                        }</div>
                                                    </>
                                                ) : (
                                                    <div className="text-yellow-700">
                                                        Lead time information will appear when supplier is loaded...
                                </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Location Tracking */}
                        <div className="space-y-6">
                            <h2 className="text-xl font-semibold border-b pb-2">Location Tracking</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Warehouse Location</label>
                                    <input
                                        type="text"
                                        name="warehouse_location"
                                        value={formData.warehouse_location}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">FBA Fulfillment Center</label>
                                    <input
                                        type="text"
                                        name="fba_fulfillment_center"
                                        value={formData.fba_fulfillment_center}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Storage Location</label>
                                    <input
                                        type="text"
                                        name="storage_location"
                                        value={formData.storage_location}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Performance Metrics */}
                        <div className="space-y-6">
                            <h2 className="text-xl font-semibold border-b pb-2">Performance Metrics</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Sales Velocity</label>
                                    <input
                                        type="number"
                                        name="sales_velocity"
                                        step="0.01"
                                        value={formData.sales_velocity}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Days of Inventory</label>
                                    <input
                                        type="number"
                                        name="days_of_inventory"
                                        step="0.01"
                                        value={formData.days_of_inventory}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Sales Rank</label>
                                    <input
                                        type="number"
                                        name="sales_rank"
                                        value={formData.sales_rank}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Customer Rating</label>
                                    <input
                                        type="number"
                                        name="customer_rating"
                                        step="0.1"
                                        min="0"
                                        max="5"
                                        value={formData.customer_rating}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Fulfillment & Shipping */}
                        <div className="space-y-6">
                            <h2 className="text-xl font-semibold border-b pb-2">Fulfillment & Shipping</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Shipping Carrier</label>
                                    <input
                                        type="text"
                                        name="shipping_carrier"
                                        value={formData.shipping_carrier}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">FBA Shipment Status</label>
                                    <input
                                        type="text"
                                        name="fba_shipment_status"
                                        value={formData.fba_shipment_status}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Date Tracking */}
                        <div className="space-y-6">
                            <h2 className="text-xl font-semibold border-b pb-2">Date Tracking</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Date Added</label>
                                    <input
                                        type="date"
                                        name="date_added"
                                        value={formData.date_added.split('T')[0]}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Last Restocked</label>
                                    <input
                                        type="date"
                                        name="last_restocked"
                                        value={formData.last_restocked?.split('T')[0] || ''}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Last Ordered</label>
                                    <input
                                        type="date"
                                        name="last_ordered"
                                        value={formData.last_ordered?.split('T')[0] || ''}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Expiration Date</label>
                                    <input
                                        type="date"
                                        name="expiration_date"
                                        value={formData.expiration_date?.split('T')[0] || ''}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Next Reorder Date</label>
                                    <input
                                        type="date"
                                        name="next_reorder_date"
                                        value={formData.next_reorder_date?.split('T')[0] || ''}
                                        onChange={handleFormInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Additional Details */}
                        <div className="space-y-6">
                            <h2 className="text-xl font-semibold border-b pb-2">Additional Details</h2>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Product Details (JSON)</label>
                                <textarea
                                    value={JSON.stringify(formData.product_details, null, 2)}
                                    onChange={(e) => {
                                        try {
                                            const parsed = JSON.parse(e.target.value);
                                            handleInputChange('product_details', parsed);
                                        } catch (err) {
                                            // Don't update if JSON is invalid
                                        }
                                    }}
                                    rows={6}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 font-mono"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white shadow-md rounded-lg p-6 mt-8">
                <h2 className="text-xl font-semibold border-b pb-2 mb-4">Product Images</h2>
                <div className="space-y-4">
                    <div 
                        ref={dropZoneRef}
                        className={`border-2 border-dashed rounded-lg p-6 transition-colors ${
                            isDragging 
                                ? 'border-blue-500 bg-blue-50' 
                                : 'border-gray-300 hover:border-blue-400'
                        }`}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onClick={openFileDialog}
                    >
                        <div className="text-center">
                            <input
                                ref={fileInputRef}
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={handleFileInputChange}
                                disabled={uploading || images.length >= 10}
                                className="hidden"
                            />
                            <svg 
                                className="mx-auto h-12 w-12 text-gray-400" 
                                fill="none" 
                                stroke="currentColor" 
                                viewBox="0 0 24 24" 
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2} 
                                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" 
                                />
                            </svg>
                            <p className="mt-1 text-sm text-gray-600">
                                Drag and drop images here, or click to select files
                            </p>
                            <p className="mt-1 text-xs text-gray-500">
                                Supports: JPG, PNG, GIF (Max 10 images)
                            </p>
                            {uploading && (
                                <div className="mt-2 flex justify-center">
                                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
                                </div>
                            )}
                            {images.length > 0 && (
                                <p className="mt-2 text-xs text-gray-500">
                                    {images.length} of 10 images uploaded
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {images.map((image: ProductImage) => {
                            return (
                                <div key={image.id} className="flex flex-col border rounded-lg overflow-hidden max-w-[250px] mx-auto w-full">
                                    {/* Image display */}
                                    <div className="relative aspect-square w-full">
                                    <img
                                        src={formatImageUrl(image.url)}
                                        alt={`Product ${formData.sku} - ${image.filename}`}
                                            className="w-full h-full object-contain cursor-pointer"
                                            onClick={() => openImageModal(image)}
                                        />
                                        {image.is_primary && (
                                            <div className="absolute top-2 right-2 bg-green-500 text-white px-2 py-1 rounded-md text-xs font-bold">
                                                PRIMARY
                                            </div>
                                        )}
                                    </div>
                                    
                                    {/* Buttons displayed below the image */}
                                    <div className="flex p-2 bg-gray-100 justify-between">
                                            {image.is_primary ? (
                                            <div className="text-green-700 text-sm font-medium bg-green-100 px-3 py-1 rounded border border-green-400">
                                                Primary Image
                                            </div>
                                            ) : (
                                                <button
                                                onClick={() => {
                                                    console.log("PRIMARY BUTTON CLICKED - image ID:", image.id);
                                                    
                                                    if (!id) return;
                                                    const productId = parseInt(id);
                                                    
                                                    toast.loading('Updating primary image...', { id: 'primary-update' });
                                                    
                                                    // Call the API directly with explicit URL
                                                    fetch(`/api/v1/inventory/products/${productId}/images/${image.id}/set-primary/`, {
                                                        method: 'PUT',
                                                        headers: { 'Content-Type': 'application/json' }
                                                    })
                                                    .then(response => {
                                                        console.log("Primary image API response status:", response.status, response.statusText);
                                                        if (response.ok) return response.json();
                                                        throw new Error(`Failed: ${response.status} ${response.statusText}`);
                                                    })
                                                    .then(data => {
                                                        console.log("Success:", data);
                                                        // Now reload the images to see the updated primary status
                                                        return fetch(`/api/v1/inventory/products/${productId}/images/`);
                                                    })
                                                    .then(response => {
                                                        if (response.ok) return response.json();
                                                        throw new Error('Failed to reload images');
                                                    })
                                                    .then(updatedImages => {
                                                        console.log("Updated images:", updatedImages);
                                                        setImages(updatedImages);
                                                        toast.success('Primary image updated', { id: 'primary-update' });
                                                    })
                                                    .catch(err => {
                                                        console.error("Error:", err);
                                                        toast.error(`Failed to update primary image: ${err.message}`, { id: 'primary-update' });
                                                    });
                                                }}
                                                className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded text-sm font-medium flex-grow mr-1"
                                                type="button"
                                            >
                                                Set as Primary
                                                </button>
                                            )}
                                        
                                        <div className="flex">
                                            <button
                                                onClick={() => showCropModal(image)}
                                                className="bg-purple-500 hover:bg-purple-600 text-white px-3 py-1 rounded-l text-sm font-medium"
                                                type="button"
                                            >
                                                Crop
                                            </button>
                                            <button
                                            onClick={() => {
                                                console.log("Delete button clicked for image:", image.id);
                                                if (!id) return;
                                                const productId = parseInt(id);
                                                
                                                toast.loading('Deleting image...', { id: 'delete-image' });
                                                
                                                fetch(`/api/v1/inventory/products/${productId}/images/${image.id}/`, {
                                                    method: 'DELETE'
                                                })
                                                .then(response => {
                                                    console.log("Delete image API response status:", response.status);
                                                    if (response.ok) {
                                                        // Now reload the images to see the updated list
                                                        return fetch(`/api/v1/inventory/products/${productId}/images/`);
                                                    }
                                                    throw new Error(`Failed to delete: ${response.status}`);
                                                })
                                                .then(response => {
                                                    if (response.ok) return response.json();
                                                    throw new Error('Failed to reload images');
                                                })
                                                .then(updatedImages => {
                                                    console.log("Images after delete:", updatedImages);
                                                    setImages(updatedImages);
                                                    toast.success('Image deleted', { id: 'delete-image' });
                                                })
                                                .catch(err => {
                                                    console.error("Error deleting image:", err);
                                                    toast.error(`Failed to delete image: ${err.message}`, { id: 'delete-image' });
                                                });
                                            }}
                                            className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded-r text-sm font-medium ml-1"
                                            type="button"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            {/* Product Labels Section */}
            <div className="bg-white shadow-md rounded-lg p-6 mt-8">
                <h2 className="text-xl font-semibold border-b pb-2 mb-4">Product Labels</h2>
                <div className="space-y-4">
                    <div 
                        ref={labelDropZoneRef}
                        className={`border-2 border-dashed rounded-lg p-6 transition-colors ${
                            isDraggingLabels 
                                ? 'border-blue-500 bg-blue-50' 
                                : 'border-gray-300 hover:border-blue-400'
                        }`}
                        onDragEnter={handleLabelDragEnter}
                        onDragOver={handleLabelDragOver}
                        onDragLeave={handleLabelDragLeave}
                        onDrop={handleLabelDrop}
                        onClick={openLabelFileDialog}
                    >
                        <div className="text-center">
                            <input
                                ref={labelInputRef}
                                type="file"
                                multiple
                                accept=".pdf,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={handleLabelInputChange}
                                disabled={uploadingLabels}
                                className="hidden"
                            />
                            <svg 
                                className="mx-auto h-12 w-12 text-gray-400" 
                                fill="none" 
                                stroke="currentColor" 
                                viewBox="0 0 24 24" 
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2} 
                                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" 
                                />
                            </svg>
                            <p className="mt-1 text-sm text-gray-600">
                                Drag and drop label documents here, or click to select files
                            </p>
                            <p className="mt-1 text-xs text-gray-500">
                                Supports: PDF, DOCX
                            </p>
                            {uploadingLabels && (
                                <div className="mt-2 flex justify-center">
                                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
                                </div>
                            )}
                        </div>
                    </div>

                    {labels.length > 0 ? (
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Label Name
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Type
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Date Added
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {labels.map(label => (
                                        <tr key={label.id}>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0 h-10 w-10 bg-gray-100 rounded flex items-center justify-center">
                                                        {label.file_type === 'pdf' ? (
                                                            <svg className="h-6 w-6 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
                                                            </svg>
                                                        ) : (
                                                            <svg className="h-6 w-6 text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
                                                            </svg>
                                                        )}
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="text-sm font-medium text-gray-900 cursor-pointer hover:text-blue-600" onClick={() => handleViewLabel(label)}>
                                                            {label.filename}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                    label.file_type === 'pdf' ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800'
                                                }`}>
                                                    {label.file_type.toUpperCase()}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {new Date(label.created_at).toLocaleDateString()}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <div className="flex justify-end space-x-3">
                                                    <button
                                                        onClick={() => handleViewLabel(label)}
                                                        className="text-indigo-600 hover:text-indigo-900 bg-indigo-50 rounded-md px-3 py-1"
                                                    >
                                                        View
                                                    </button>
                                                    <button
                                                        onClick={() => handlePrintLabel(label)}
                                                        className="text-green-600 hover:text-green-900 bg-green-50 rounded-md px-3 py-1"
                                                    >
                                                        Print
                                                    </button>
                                                    <button
                                                        onClick={() => handleDeleteLabel(label.id)}
                                                        className="text-red-600 hover:text-red-900 bg-red-50 rounded-md px-3 py-1"
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="text-center py-4 text-gray-500">
                            No labels uploaded yet
                        </div>
                    )}
                </div>
            </div>

            {/* 3D Print Files */}
            <div className="bg-white shadow-sm rounded-lg p-6 mb-6">
                <h2 className="text-xl font-semibold mb-4">3D Print Files</h2>
                <div className="space-y-4">
                    <div 
                        ref={printFileDropZoneRef}
                        className={`border-2 border-dashed rounded-lg p-6 flex flex-col items-center justify-center ${
                            isDraggingPrintFiles ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
                        }`}
                        onDragEnter={handlePrintFileDragEnter}
                        onDragLeave={handlePrintFileDragLeave}
                        onDragOver={handlePrintFileDragOver}
                        onDrop={handlePrintFileDrop}
                        onClick={openPrintFileDialog}
                    >
                        <input
                            type="file"
                            ref={printFileInputRef}
                            onChange={handlePrintFileInputChange}
                            className="hidden"
                            accept=".stl,.obj,.amf,.3mf,.gcode"
                            multiple
                        />
                        <svg
                            className="w-12 h-12 text-gray-400 mb-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            />
                        </svg>
                        <p className="text-gray-600 mb-1">
                            {uploadingPrintFiles
                                ? 'Uploading files...'
                                : isDraggingPrintFiles
                                ? 'Drop files here'
                                : 'Drag & drop 3D print files here or click to browse'}
                        </p>
                        <p className="text-sm text-gray-500">
                            Supported formats: STL, OBJ, AMF, 3MF, GCODE
                        </p>
                    </div>

                    {/* 3D Print Files List */}
                    <div className="mt-4">
                        {printFiles.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {printFiles.map((printFile) => (
                                    <div
                                        key={printFile.id}
                                        className="border rounded-lg overflow-hidden bg-gray-50 flex flex-col"
                                    >
                                        <div className="p-4 flex-grow">
                                            <div className="flex items-center justify-between mb-2">
                                                <p className="font-medium truncate" title={printFile.filename}>
                                                    {printFile.filename}
                                                </p>
                                                <span className="text-xs px-2 py-1 bg-blue-100 text-blue-800 rounded-full">
                                                    {printFile.file_type.toUpperCase()}
                                                </span>
                                            </div>
                                            <p className="text-sm text-gray-600 mb-4 truncate">
                                                {new Date(printFile.created_at).toLocaleDateString()}
                                            </p>
                                        </div>
                                        <div className="flex border-t bg-gray-100">
                                            <button
                                                className="flex-1 px-3 py-2 text-sm text-gray-600 hover:bg-gray-200 flex justify-center items-center"
                                                onClick={() => openPrintFilePreview(printFile)}
                                            >
                                                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                                                </svg>
                                                Preview
                                            </button>
                                            <button
                                                className="flex-1 px-3 py-2 text-sm text-gray-600 hover:bg-gray-200 flex justify-center items-center"
                                                onClick={() => handleDownloadPrintFile(printFile)}
                                            >
                                                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
                                                </svg>
                                                Download
                                            </button>
                                            <button
                                                className="flex-1 px-3 py-2 text-sm text-red-600 hover:bg-red-100 flex justify-center items-center"
                                                onClick={() => handleDeletePrintFile(printFile.id)}
                                            >
                                                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                                </svg>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-center py-8 text-gray-500">
                                No 3D print files yet. Upload files to get started.
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* 3D Print File Preview Modal */}
            {selectedPrintFile && (
                <div className="fixed inset-0 z-50 flex flex-col bg-black bg-opacity-90 overflow-hidden">
                    <div className="bg-white p-4 flex justify-between items-center">
                        <h3 className="text-xl font-semibold text-gray-800">{selectedPrintFile.filename}</h3>
                        <button 
                            onClick={closePrintFilePreview}
                            className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="flex-grow flex items-center justify-center overflow-auto bg-gray-100 p-4">
                        <div className="w-full h-full flex flex-col items-center justify-center bg-white">
                            {loadingPreview ? (
                                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                            ) : (
                                selectedPrintFile.file_type.toLowerCase() === 'stl' && stlPreviewData ? (
                                    <div className="w-full h-full flex flex-col">
                                        <div className="relative" style={{ height: "80vh" }}>
                                            <StlViewer
                                                width={1200}
                                                height={Math.floor(window.innerHeight * 0.8)}
                                                style={{
                                                    backgroundColor: "#f8f9fa",
                                                    outline: "1px solid #e2e8f0",
                                                    borderRadius: "8px",
                                                    width: "100%", 
                                                    height: "100%"
                                                }}
                                                modelProps={{ 
                                                    color: modelOptions.color,
                                                    scale: 1.5
                                                }}
                                                orbitControls={true}
                                                shadows={modelOptions.shadow > 0}
                                                showAxes={modelOptions.showAxes}
                                                url={`data:model/stl;base64,${stlPreviewData}`}
                                            />
                                        </div>
                                        <div className="mt-6 space-y-4 p-4 bg-white">
                                            {/* Color Palette */}
                                            <div className="flex flex-col space-y-2">
                                                <label className="text-sm font-medium text-gray-700">Color</label>
                                                <div className="flex flex-wrap gap-2">
                                                    {colorPalette.map(color => (
                                                        <button
                                                            key={color}
                                                            onClick={() => toggleModelOption('color', color)}
                                                            className={`w-8 h-8 rounded-full hover:scale-110 transition-transform ${modelOptions.color === color ? 'ring-2 ring-offset-2 ring-blue-500' : ''}`}
                                                            style={{ backgroundColor: color }}
                                                            title={color}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            
                                            {/* Shadow Slider */}
                                            <div className="flex flex-col space-y-2">
                                                <label className="text-sm font-medium text-gray-700">Shadow Intensity: {(modelOptions.shadow * 100).toFixed(0)}%</label>
                                                <input
                                                    type="range"
                                                    min="0"
                                                    max="1"
                                                    step="0.1"
                                                    value={modelOptions.shadow}
                                                    onChange={(e) => toggleModelOption('shadow', parseFloat(e.target.value))}
                                                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                                />
                                            </div>
                                            
                                            {/* Control Buttons */}
                                            <div className="flex space-x-4 justify-center">
                                                <button 
                                                    onClick={() => toggleModelOption('rotate')}
                                                    className={`px-3 py-2 rounded ${modelOptions.rotate ? 'bg-green-100 text-green-800 hover:bg-green-200' : 'bg-gray-100 text-gray-800 hover:bg-gray-200'}`}
                                                >
                                                    {modelOptions.rotate ? 'Stop Rotation' : 'Start Rotation'}
                                                </button>
                                                <button 
                                                    onClick={() => toggleModelOption('showAxes')}
                                                    className={`px-3 py-2 rounded ${modelOptions.showAxes ? 'bg-purple-100 text-purple-800 hover:bg-purple-200' : 'bg-gray-100 text-gray-800 hover:bg-gray-200'}`}
                                                >
                                                    {modelOptions.showAxes ? 'Hide Axes' : 'Show Axes'}
                                                </button>
                                                <button 
                                                    onClick={captureModelSnapshot}
                                                    className="px-3 py-2 rounded bg-blue-500 text-white hover:bg-blue-600"
                                                >
                                                    <span className="flex items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                                                        </svg>
                                                        Take Snapshot
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="text-center p-6">
                                        <svg className="w-20 h-20 mx-auto text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                                        </svg>
                                        <p className="text-lg font-medium text-gray-700 mb-2">
                                            {selectedPrintFile.file_type.toUpperCase()} File
                                        </p>
                                        <p className="text-gray-500">
                                            {selectedPrintFile.file_type.toLowerCase() === 'stl' 
                                                ? 'Unable to load 3D preview. You can still download the file.' 
                                                : `Preview not available for ${selectedPrintFile.file_type.toUpperCase()} files. Please download to view.`}
                                        </p>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                    <div className="bg-white p-4 border-t flex justify-end">
                        <button
                            onClick={() => handleDownloadPrintFile(selectedPrintFile)}
                            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                        >
                            Download
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default InventoryDetailsPage; 