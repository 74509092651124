/**
 * Security utilities to protect the application
 */

import { Dispatch, SetStateAction } from 'react';

interface AuthState {
  token: string;
  expiry: number;
}

/**
 * Monitors localStorage for tampering and ensures auth state consistency
 * between localStorage and application state
 */
export const initSecurityMonitor = (
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>,
  logout: () => void
): (() => void) => {
  // Store original localStorage methods
  const originalGetItem = localStorage.getItem;
  const originalSetItem = localStorage.setItem;
  const originalRemoveItem = localStorage.removeItem;
  
  // Create a closure with the current auth state
  let lastKnownAuthState = localStorage.getItem('authData');
  
  // Override localStorage methods to detect tampering
  localStorage.getItem = function(key: string): string | null {
    const value = originalGetItem.call(localStorage, key);
    
    // If someone is trying to access auth data, verify it hasn't been tampered with
    if (key === 'authData' && value !== lastKnownAuthState) {
      // If there's a mismatch, someone may have modified localStorage
      if (value && lastKnownAuthState) {
        try {
          // Check if it's a valid auth state
          const parsedValue: AuthState = JSON.parse(value);
          const now = Date.now();
          
          // Only accept it if it's a valid non-expired token
          if (parsedValue.token && parsedValue.expiry && parsedValue.expiry > now) {
            lastKnownAuthState = value;
          } else {
            // Invalid or expired token - likely tampering
            console.warn('[Security] Auth data integrity check failed');
            setTimeout(() => logout(), 0);
            return null;
          }
        } catch (e) {
          // Invalid JSON - definitely tampering
          console.warn('[Security] Auth data integrity check failed');
          setTimeout(() => logout(), 0);
          return null;
        }
      } else if (value && !lastKnownAuthState) {
        // Someone added auth data that didn't exist before
        console.warn('[Security] Auth data integrity check failed');
        setTimeout(() => logout(), 0);
        return null;
      }
    }
    
    return value;
  };
  
  localStorage.setItem = function(key: string, value: string): void {
    // Update our known state if it's auth data
    if (key === 'authData') {
      lastKnownAuthState = value;
    }
    originalSetItem.call(localStorage, key, value);
  };
  
  localStorage.removeItem = function(key: string): void {
    if (key === 'authData') {
      lastKnownAuthState = null;
    }
    originalRemoveItem.call(localStorage, key);
  };
  
  // Periodically verify authentication state in multiple ways
  const intervalId = setInterval(() => {
    try {
      // Check 1: Does authData exist when we're authenticated?
      const authData = originalGetItem.call(localStorage, 'authData');
      const currentIsAuthenticated = authData !== null;
      
      // Check 2: Is it valid?
      let isValid = false;
      if (authData) {
        try {
          const parsed: AuthState = JSON.parse(authData);
          isValid = Boolean(parsed.token) && Boolean(parsed.expiry) && parsed.expiry > Date.now();
        } catch (e) {
          isValid = false;
        }
      }
      
      // If something is wrong, log the user out
      if (!currentIsAuthenticated || !isValid) {
        setIsAuthenticated(false);
      }
    } catch (e) {
      // Any error means tampering
      console.warn('[Security] Auth state verification failed');
      logout();
    }
  }, 2000); // Check every 2 seconds
  
  // Return cleanup function
  return () => {
    clearInterval(intervalId);
    localStorage.getItem = originalGetItem;
    localStorage.setItem = originalSetItem;
    localStorage.removeItem = originalRemoveItem;
  };
};

/**
 * Creates a tamper-resistant token
 */
export const createSecureToken = (): string => {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}; 