import React from 'react';
import './index.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Header from './components/Header';
import SearchPage from './pages/SearchPage';
import SavedItemsPage from './pages/SavedItemsPage';
import ItemDetailPage from './pages/ItemDetailPage';
import HiddenItemsPage from './pages/HiddenItemsPage';
import SavedSearchesPage from './pages/SavedSearchesPage';
import AdminPage from './pages/AdminPage';
import LoginPage from './pages/LoginPage';
import InventoryPage from './pages/InventoryPage';
import InventoryDetailsPage from './pages/InventoryDetailsPage';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import StatisticsPage from './pages/StatisticsPage';
import NeedsReorderPage from './pages/NeedsReorderPage';
import SuppliersPage from './pages/SuppliersPage';
import SupplierDetailsPage from './pages/SupplierDetailsPage';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Toaster 
          position="top-right"
          toastOptions={{
            duration: 3000,
            style: {
              background: '#363636',
              color: '#fff',
            },
            success: {
              duration: 3000,
              iconTheme: {
                primary: '#10B981',
                secondary: 'white',
              },
            },
            error: {
              duration: 4000,
              iconTheme: {
                primary: '#EF4444',
                secondary: 'white',
              },
            },
          }}
        />
        
        <Header />
        <main className="content-container">
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={
              <ProtectedRoute>
                <SearchPage />
              </ProtectedRoute>
            } />
            <Route path="/saved" element={
              <ProtectedRoute>
                <SavedItemsPage />
              </ProtectedRoute>
            } />
            <Route path="/item/:id" element={
              <ProtectedRoute>
                <ItemDetailPage />
              </ProtectedRoute>
            } />
            <Route path="/hidden-items" element={
              <ProtectedRoute>
                <HiddenItemsPage />
              </ProtectedRoute>
            } />
            <Route path="/saved-searches" element={
              <ProtectedRoute>
                <SavedSearchesPage />
              </ProtectedRoute>
            } />
            <Route path="/admin" element={
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            } />
            <Route path="/inventory" element={
              <ProtectedRoute>
                <InventoryPage />
              </ProtectedRoute>
            } />
            <Route path="/inventory/:id" element={
              <ProtectedRoute>
                <InventoryDetailsPage />
              </ProtectedRoute>
            } />
            <Route path="/statistics" element={
              <ProtectedRoute>
                <StatisticsPage />
              </ProtectedRoute>
            } />
            <Route path="/needs-reorder" element={
              <ProtectedRoute>
                <NeedsReorderPage />
              </ProtectedRoute>
            } />
            <Route path="/suppliers" element={
              <ProtectedRoute>
                <SuppliersPage />
              </ProtectedRoute>
            } />
            <Route path="/suppliers/:id" element={
              <ProtectedRoute>
                <SupplierDetailsPage />
              </ProtectedRoute>
            } />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
        
        {/* Footer */}
        <footer className="bg-gray-800 text-white py-8 mt-16">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row justify-between items-center">
     

            </div>
            
            <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400 text-sm">
              <p>&copy; {new Date().getFullYear()} Recycled Brand. All rights reserved.</p>
            </div>
          </div>
        </footer>
      </Router>
    </AuthProvider>
  );
};

export default App; 