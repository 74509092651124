import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSavedSearches, deleteSavedSearch, executeSavedSearch, updateSavedSearch } from '../services/api';
import { SavedSearch } from '../types';

const SavedSearchesPage: React.FC = () => {
  const [savedSearches, setSavedSearches] = useState<SavedSearch[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [editingSearchId, setEditingSearchId] = useState<number | null>(null);
  const [editFormData, setEditFormData] = useState<{
    query: string;
    sort_by: string;
    sort_order: string;
    category?: string;
  }>({
    query: '',
    sort_by: 'popularity',
    sort_order: 'asc',
    category: '',
  });
  const pageTopRef = useRef<HTMLDivElement>(null);
  
  const navigate = useNavigate();

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchSavedSearches = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await getSavedSearches();
      setSavedSearches(data);
    } catch (error) {
      console.error('Error fetching saved searches:', error);
      setError('Failed to load saved searches. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSavedSearches();
  }, [fetchSavedSearches]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
      
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleDelete = async (searchId: number) => {
    if (!window.confirm('Are you sure you want to delete this saved search?')) {
      return;
    }
    
    try {
      await deleteSavedSearch(searchId);
      setSavedSearches(prev => prev.filter(search => search.id !== searchId));
      setSuccessMessage('Search deleted successfully!');
    } catch (error) {
      console.error('Error deleting saved search:', error);
      setError('Failed to delete search. Please try again.');
    }
  };

  const handleExecute = async (searchId: number) => {
    try {
      const searchParams = await executeSavedSearch(searchId);
      // Make sure all parameters are valid before navigating
      const validParams = {
        query: searchParams.query || '',
        sort_by: searchParams.sort_by || 'popularity',
        sort_order: searchParams.sort_order || 'desc',
        // Only include category if it's not null or undefined
        ...(searchParams.category ? { category: searchParams.category } : {})
      };
      
      navigate('/', { 
        state: { 
          searchParams: validParams 
        }
      });
    } catch (error) {
      console.error('Error executing saved search:', error);
      setError('Failed to execute search. Please try again.');
    }
  };

  const handleEdit = (search: SavedSearch) => {
    setEditingSearchId(search.id);
    setEditFormData({
      query: search.query,
      sort_by: search.sort_by,
      sort_order: search.sort_order,
      category: search.category,
    });
  };

  const handleCancelEdit = () => {
    setEditingSearchId(null);
  };

  const handleUpdateSearch = async (searchId: number) => {
    try {
      await updateSavedSearch(searchId, editFormData);
      setSuccessMessage('Search updated successfully!');
      setEditingSearchId(null);
      fetchSavedSearches();
    } catch (error) {
      console.error('Error updating saved search:', error);
      setError('Failed to update search. Please try again.');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setEditFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8" ref={pageTopRef}>
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-3xl font-bold mb-8">Saved Searches</h1>

        {/* Success message */}
        {successMessage && (
          <div className="mb-6">
            <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-green-700">{successMessage}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Error message */}
        {error && (
          <div className="mb-6">
            <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Loading state */}
        {isLoading && (
          <div className="flex justify-center items-center py-12">
            <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}

        {/* Saved searches table */}
        {!isLoading && savedSearches.length === 0 && (
          <div className="bg-white rounded-lg shadow-md p-6 text-center">
            <p className="text-gray-500">No saved searches found. Search for items and save your searches to see them here.</p>
          </div>
        )}

        {!isLoading && savedSearches.length > 0 && (
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Query
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Category
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Sort
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Count
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Last Used
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {savedSearches.map((search) => (
                  <tr key={search.id}>
                    {editingSearchId === search.id ? (
                      <td colSpan={5} className="px-6 py-4">
                        <div className="space-y-4">
                          <div>
                            <label htmlFor="query" className="block text-sm font-medium text-gray-700 mb-1">
                              Query
                            </label>
                            <input
                              type="text"
                              name="query"
                              id="query"
                              value={editFormData.query}
                              onChange={handleInputChange}
                              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                              required
                            />
                          </div>
                          
                          <div className="grid grid-cols-3 gap-4">
                            <div>
                              <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                                Category
                              </label>
                              <input
                                type="text"
                                name="category"
                                id="category"
                                value={editFormData.category || ''}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                            
                            <div>
                              <label htmlFor="sort_by" className="block text-sm font-medium text-gray-700 mb-1">
                                Sort By
                              </label>
                              <select
                                name="sort_by"
                                id="sort_by"
                                value={editFormData.sort_by}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                              >
                                <option value="popularity">Popularity</option>
                                <option value="date">Date</option>
                                <option value="title">Title</option>
                                <option value="category">Category</option>
                              </select>
                            </div>
                            
                            <div>
                              <label htmlFor="sort_order" className="block text-sm font-medium text-gray-700 mb-1">
                                Sort Order
                              </label>
                              <select
                                name="sort_order"
                                id="sort_order"
                                value={editFormData.sort_order}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                              >
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                              </select>
                            </div>
                          </div>
                          
                          <div className="flex justify-end space-x-3">
                            <button
                              type="button"
                              onClick={handleCancelEdit}
                              className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              onClick={() => handleUpdateSearch(search.id)}
                              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            onClick={() => handleExecute(search.id)}
                            className="text-blue-600 hover:text-blue-900 font-medium hover:underline"
                          >
                            {search.query}
                          </button>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {search.category || 'All Categories'}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {search.sort_by} ({search.sort_order})
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 py-1 text-xs font-medium bg-blue-100 text-blue-800 rounded-full">
                            {search.search_count}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {new Date(search.updated_at).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                          <button
                            onClick={() => handleEdit(search)}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDelete(search.id)}
                            className="text-red-600 hover:text-red-900"
                          >
                            Delete
                          </button>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default SavedSearchesPage; 