/**
 * Utility functions for handling URLs in the application.
 */

/**
 * Ensures that static file URLs are properly formatted as relative URLs.
 * If the URL starts with /static/, it's returned as is.
 * If the URL starts with http(s), it's converted to a relative URL.
 * Otherwise, it's treated as a relative URL and returned as is.
 */
export const formatImageUrl = (url: string): string => {
    if (!url) return '';
    
    // If it's already a relative URL starting with /static/, return as is
    if (url.startsWith('/static/')) {
        return url;
    }
    
    // If it's an absolute URL (http/https), convert to relative
    if (url.startsWith('http://') || url.startsWith('https://')) {
        try {
            const urlObj = new URL(url);
            // If the pathname starts with /static/, return it as is
            if (urlObj.pathname.startsWith('/static/')) {
                return urlObj.pathname;
            }
            // Otherwise, ensure it starts with /static/
            return `/static${urlObj.pathname.startsWith('/') ? urlObj.pathname : `/${urlObj.pathname}`}`;
        } catch {
            return url;
        }
    }
    
    // If it's a relative URL without leading slash, add it
    return url.startsWith('/') ? url : `/${url}`;
}; 