import React from 'react';
import Navbar from '../components/Navbar';

const NeedsReorderPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">Products Needing Reorder</h1>
        <div className="bg-white rounded-lg shadow-md p-6">
          <p className="text-gray-700">Needs reorder dashboard is under development.</p>
        </div>
      </div>
    </div>
  );
};

export default NeedsReorderPage; 