import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const LoginPage: React.FC = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [validationError, setValidationError] = useState('');
  const { login, isAuthenticated, isLocked, loginAttempts, lockoutTimeRemaining } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Get the redirect path from location state
  const from = location.state?.from?.pathname || "/";

  // Format remaining lockout time
  const formatLockoutTime = () => {
    const minutes = Math.floor(lockoutTimeRemaining / 60);
    const seconds = lockoutTimeRemaining % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Check if locked
    if (isLocked) {
      setError(`Account is locked. Try again in ${formatLockoutTime()}`);
      return;
    }
    
    // Validate password strength
    if (password.length < 8) {
      setValidationError('Password must be at least 8 characters long');
      return;
    }
    
    if (!/[A-Z]/.test(password)) {
      setValidationError('Password must contain at least one uppercase letter');
      return;
    }
    
    if (!/[0-9]/.test(password)) {
      setValidationError('Password must contain at least one number');
      return;
    }
    
    if (!/[^A-Za-z0-9]/.test(password)) {
      setValidationError('Password must contain at least one special character');
      return;
    }
    
    // Attempt login
    const success = login(password);
    
    if (success) {
      // Redirect to original page or home
      navigate(from, { replace: true });
    } else {
      if (isLocked) {
        setError(`Too many failed attempts. Account is locked for ${formatLockoutTime()}`);
      } else {
        setError(`Invalid password. ${MAX_LOGIN_ATTEMPTS - loginAttempts} attempts remaining.`);
      }
    }
  };

  // If already authenticated, redirect to home or original page
  if (isAuthenticated) {
    return <Navigate to={from} replace />;
  }

  // Validate password
  const validatePassword = (value: string) => {
    setPassword(value);
    
    // Clear validation error when user types
    if (validationError) {
      setValidationError('');
    }
  };
  
  // Constants for display
  const MAX_LOGIN_ATTEMPTS = 10;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to Project Cursor
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => validatePassword(e.target.value)}
              />
            </div>
          </div>
          
          {error && (
            <div className="text-red-500 text-sm">
              {error}
            </div>
          )}
          
          {validationError && (
            <div className="text-red-500 text-sm">
              {validationError}
            </div>
          )}
          
          {isLocked && (
            <div className="text-red-500 text-sm">
              Account is locked. Try again in {formatLockoutTime()}
            </div>
          )}

          <div>
            <button
              type="submit"
              disabled={isLocked}
              className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${isLocked ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'}`}
            >
              {isLocked ? `Locked (${formatLockoutTime()})` : 'Sign in'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage; 