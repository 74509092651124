import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  getSavedItem, 
  updateSavedItem, 
  deleteSavedItem, 
  getThingiverseFiles, 
  saveItemFiles, 
  getSavedItemFiles,
  deleteSavedItemFile,
  API_BASE_URL,
  getCompetitorUrls,
  createCompetitorUrl,
  updateCompetitorUrl,
  deleteCompetitorUrl
} from '../services/api';
import { inventoryService } from '../services/inventoryService';
import { SavedItem, ThingiverseFile, CompetitorUrl } from '../types';
import { Supplier } from '../types/inventory';
import { toast } from 'react-hot-toast';

const ItemDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [item, setItem] = useState<SavedItem | null>(null);
  const [formData, setFormData] = useState<Partial<SavedItem>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [imageError, setImageError] = useState(false);
  
  // Supplier state
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [isLoadingSuppliers, setIsLoadingSuppliers] = useState(false);
  
  // New states for files
  const [files, setFiles] = useState<ThingiverseFile[]>([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  
  // New states for competitor URLs
  const [competitorUrls, setCompetitorUrls] = useState<CompetitorUrl[]>([]);
  const [isLoadingCompetitorUrls, setIsLoadingCompetitorUrls] = useState(false);
  const [showCompetitorUrlModal, setShowCompetitorUrlModal] = useState(false);
  const [editingCompetitorUrl, setEditingCompetitorUrl] = useState<CompetitorUrl | null>(null);
  const [competitorUrlFormData, setCompetitorUrlFormData] = useState<{
    url: string;
    name: string;
    sales_estimate: string;
    notes: string;
  }>({
    url: '',
    name: '',
    sales_estimate: '',
    notes: ''
  });

  // Fetch suppliers
  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        setIsLoadingSuppliers(true);
        const data = await inventoryService.getSuppliers();
        setSuppliers(data);
        setIsLoadingSuppliers(false);
      } catch (error) {
        console.error('Error fetching suppliers:', error);
        setIsLoadingSuppliers(false);
      }
    };
    
    fetchSuppliers();
  }, []);

  useEffect(() => {
    const fetchItem = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const data = await getSavedItem(Number(id));
        
        console.log('Item Detail - Thumbnail URL:', data.thumbnail_url);
        
        // Add default status if not present
        if (!data.status) {
          data.status = 'active';
        }
        
        // Check if the URL is valid
        if (data.thumbnail_url) {
          // Log the URL and try different formats
          console.log('Original URL:', data.thumbnail_url);
          console.log('Alternative URL 1:', tryAlternativeImageUrl(data.thumbnail_url));
          
          // Try to fetch the image directly
          try {
            const response = await fetch(data.thumbnail_url, { method: 'HEAD' });
            console.log('Image fetch response:', response.status, response.statusText);
            
            // Try alternative URL if original fails
            if (response.status !== 200) {
              const altUrl = tryAlternativeImageUrl(data.thumbnail_url);
              if (altUrl) {
                const altResponse = await fetch(altUrl, { method: 'HEAD' });
                console.log('Alternative image fetch response:', altResponse.status, altResponse.statusText);
              }
            }
          } catch (err) {
            console.error('Error fetching image:', err);
          }
        }
        
        setItem(data);
        setFormData(data);
      } catch (err) {
        console.error('Error fetching item:', err);
        setError('Failed to load item details');
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchItem();
    }
  }, [id]);

  // Add useEffect to auto-load files when item loads
  useEffect(() => {
    const loadFiles = async () => {
      if (!item?.thingiverse_id) return;
      
      try {
        setIsLoadingFiles(true);
        
        try {
          // Try to get existing files from database
          const savedFiles = await getSavedItemFiles(item.thingiverse_id);
          if (savedFiles && Array.isArray(savedFiles) && savedFiles.length > 0) {
            console.log('Found saved files in database:', savedFiles.length);
            setFiles(savedFiles);
            setShowFiles(true);
          }
        } catch (err) {
          console.log('No existing files found in database:', err);
        }
      } catch (err) {
        console.error('Error loading files:', err);
      } finally {
        setIsLoadingFiles(false);
      }
    };
    
    if (item) {
      loadFiles();
    }
  }, [item]);

  // New useEffect to load competitor URLs when item loads
  useEffect(() => {
    const loadCompetitorUrls = async () => {
      if (!item?.id) return;
      
      try {
        setIsLoadingCompetitorUrls(true);
        setError(null);
        
        const urls = await getCompetitorUrls(item.id);
        setCompetitorUrls(urls);
      } catch (err) {
        console.error('Error loading competitor URLs:', err);
        // We don't set an error here to avoid disrupting the main page experience
      } finally {
        setIsLoadingCompetitorUrls(false);
      }
    };
    
    if (item) {
      loadCompetitorUrls();
    }
  }, [item]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    
    // Handle checkbox inputs
    if (type === 'checkbox') {
      const checked = (e.target as HTMLInputElement).checked;
      setFormData(prev => ({ ...prev, [name]: checked }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  // Handle supplier selection change
  const handleSupplierChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const supplierId = e.target.value ? parseInt(e.target.value, 10) : null;
    console.log(`🔍 handleSupplierChange called with value: ${e.target.value}, parsed as: ${supplierId}`);
    
    // Add clear visual feedback
    const selectElement = e.target;
    selectElement.style.border = '2px solid green';
    
    const saveMessage = document.createElement('div');
    saveMessage.textContent = 'Saving supplier...';
    saveMessage.style.color = 'green';
    saveMessage.style.fontWeight = 'bold';
    saveMessage.style.marginTop = '5px';
    saveMessage.id = 'supplier-save-message';
    
    // Remove any existing message
    const existingMessage = document.getElementById('supplier-save-message');
    if (existingMessage) {
      existingMessage.remove();
    }
    
    // Add the message after the select element
    selectElement.parentNode?.appendChild(saveMessage);
    
    // Create new form data with updated supplier
    const newFormData = { ...formData, supplier_id: supplierId };
    console.log(`🔍 New formData after supplier change:`, newFormData);
    
    // Update state
    setFormData(newFormData);
    
    // Directly save the change instead of submitting the form
    if (!id) return;
    
    try {
      console.log(`🔍 Directly saving supplier_id: ${supplierId}`);
      
      setIsSaving(true);
      setError(null);
      
      const updatedItem = await updateSavedItem(Number(id), { supplier_id: supplierId });
      console.log('🔍 Response from direct supplier update:', updatedItem);
      console.log('🔍 supplier_id in response:', updatedItem.supplier_id);
      
      setItem(updatedItem);
      
      // Update message after save
      setTimeout(() => {
        if (document.getElementById('supplier-save-message')) {
          document.getElementById('supplier-save-message')!.textContent = 'Supplier saved!';
          
          // Remove the message after 2 seconds
          setTimeout(() => {
            if (document.getElementById('supplier-save-message')) {
              document.getElementById('supplier-save-message')!.remove();
            }
            // Reset the border color
            selectElement.style.border = '';
          }, 2000);
        }
      }, 500);
      
      // Show toast notification
      const supplierName = suppliers.find(s => s.id === supplierId)?.name || 'Selected supplier';
      toast.success(`Supplier updated to "${supplierName}"`, {
        duration: 4000,
        position: 'top-center',
        style: {
          background: '#10b981',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '16px',
          borderRadius: '10px',
          padding: '16px'
        }
      });
      
    } catch (err) {
      console.error('🔍 Error directly saving supplier:', err);
      
      // Update message to show error
      if (document.getElementById('supplier-save-message')) {
        document.getElementById('supplier-save-message')!.textContent = 'Error saving supplier!';
        document.getElementById('supplier-save-message')!.style.color = 'red';
      }
      
      setError('Failed to update supplier');
      toast.error('Failed to update supplier', {
        duration: 4000,
        position: 'top-center',
        style: {
          background: '#ef4444',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '16px',
          borderRadius: '10px',
          padding: '16px'
        }
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!id) return;
    
    try {
      setIsSaving(true);
      setError(null);
      
      console.log('🔍 Submitting form with data:', formData);
      console.log('🔍 supplier_id in formData:', formData.supplier_id);
      console.log('🔍 Full request payload:', JSON.stringify(formData, null, 2));
      
      const updatedItem = await updateSavedItem(Number(id), formData);
      console.log('🔍 Response from updateSavedItem:', updatedItem);
      console.log('🔍 supplier_id in response:', updatedItem.supplier_id);
      
      setItem(updatedItem);
      
      // More visible toast notification
      toast.success(`Item updated successfully with supplier ID: ${updatedItem.supplier_id}`, {
        duration: 4000,
        position: 'top-center',
        style: {
          background: '#10b981',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '16px',
          borderRadius: '10px',
          padding: '16px'
        }
      });
    } catch (err) {
      console.error('🔍 Error updating item:', err);
      setError('Failed to update item');
      toast.error('Failed to update item', {
        duration: 4000,
        position: 'top-center',
        style: {
          background: '#ef4444',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '16px',
          borderRadius: '10px',
          padding: '16px'
        }
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (!id || !window.confirm('Are you sure you want to delete this item?')) return;
    
    try {
      setIsLoading(true);
      await deleteSavedItem(Number(id));
      toast.success('Item deleted successfully');
      navigate('/saved-items');
    } catch (err) {
      console.error('Error deleting item:', err);
      setError('Failed to delete item');
      toast.error('Failed to delete item');
      setIsLoading(false);
    }
  };

  const tryAlternativeImageUrl = (originalUrl: string) => {
    if (originalUrl.includes('_preview_card')) {
      return originalUrl.replace('_preview_card', '');
    } else if (originalUrl.endsWith('.jpg')) {
      return originalUrl.replace('.jpg', '.png');
    } else if (originalUrl.endsWith('.png')) {
      return originalUrl.replace('.png', '.jpg');
    }
    return null;
  };

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const target = e.target as HTMLImageElement;
    const originalSrc = target.src;
    
    // Try alternative URL format
    const alternativeSrc = tryAlternativeImageUrl(originalSrc);
    
    if (alternativeSrc && alternativeSrc !== originalSrc) {
      target.src = alternativeSrc;
    } else {
      // If all attempts fail, show fallback
      setImageError(true);
    }
  };

  // Function to fetch files from Thingiverse API and save them
  const handleFetchFiles = async () => {
    if (!item?.thingiverse_id) return;
    
    try {
      setIsLoadingFiles(true);
      setError(null);
      
      // Try to fetch existing files first
      try {
        const savedFiles = await getSavedItemFiles(item.thingiverse_id);
        if (savedFiles && savedFiles.length > 0) {
          setFiles(savedFiles);
          setShowFiles(true);
          toast.success('Loaded existing file data');
          setIsLoadingFiles(false);
          return;
        }
      } catch (err) {
        console.log('No existing files found, fetching from Thingiverse');
      }
      
      try {
        // Fetch from Thingiverse if no saved files
        const fetchedFiles = await getThingiverseFiles(item.thingiverse_id);
        
        // Only attempt to save files if we get valid data
        if (fetchedFiles && Array.isArray(fetchedFiles) && fetchedFiles.length > 0) {
          try {
            // Try to save the files to the database
            await saveItemFiles(item.thingiverse_id, fetchedFiles);
            toast.success('File data fetched and saved successfully');
          } catch (saveErr) {
            console.error('Error saving file data:', saveErr);
            // Even if saving fails, we still show the files we fetched
            toast.error('File data fetched but could not be saved for future use');
          }
          
          setFiles(fetchedFiles);
          setShowFiles(true);
        } else {
          // Direct API request if backend fails
          try {
            // Fallback to direct request to Thingiverse with proxy
            const response = await fetch(`https://cors-anywhere.herokuapp.com/https://api.thingiverse.com/things/${item.thingiverse_id}/files?access_token=9ae9213e5b4acf98ba69cf0e3cf3c5cb`);
            
            if (response.ok) {
              const filesData = await response.json();
              if (Array.isArray(filesData) && filesData.length > 0) {
                setFiles(filesData);
                setShowFiles(true);
                toast.success('File data fetched directly from Thingiverse');
                
                // Try to save these files too
                try {
                  await saveItemFiles(item.thingiverse_id, filesData);
                } catch (directSaveErr) {
                  console.error('Error saving directly fetched files:', directSaveErr);
                }
              } else {
                throw new Error('No files found for this item');
              }
            } else {
              throw new Error(`Failed to fetch data: ${response.status}`);
            }
          } catch (directErr) {
            console.error('Error with direct Thingiverse request:', directErr);
            throw new Error('All file fetching methods failed');
          }
        }
      } catch (fetchErr) {
        console.error('Error fetching file data:', fetchErr);
        setError('Failed to fetch file data from Thingiverse. Please try again later or check if this item has downloadable files.');
        toast.error('Failed to fetch file data');
      }
    } catch (err) {
      console.error('Error in file fetching process:', err);
      setError('Failed to fetch file data from Thingiverse');
      toast.error('Failed to fetch file data');
    } finally {
      setIsLoadingFiles(false);
    }
  };

  // Function to delete a file
  const handleDeleteFile = async (fileId: number) => {
    if (!window.confirm('Are you sure you want to delete this file?')) return;
    
    try {
      await deleteSavedItemFile(fileId);
      // Remove the file from the state
      setFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
      toast.success('File deleted successfully');
    } catch (err) {
      console.error('Error deleting file:', err);
      toast.error('Failed to delete file');
    }
  };

  const handleCompetitorUrlChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setCompetitorUrlFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddCompetitorUrl = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!item?.id) return;
    
    try {
      setIsSaving(true);
      setError(null);
      
      // Convert sales estimate to number or null
      const salesEstimate = competitorUrlFormData.sales_estimate ? 
        parseInt(competitorUrlFormData.sales_estimate, 10) : 
        undefined;
      
      const newUrl = await createCompetitorUrl(item.id, {
        url: competitorUrlFormData.url,
        name: competitorUrlFormData.name || undefined,
        sales_estimate: salesEstimate,
        notes: competitorUrlFormData.notes || undefined
      });
      
      setCompetitorUrls(prev => [newUrl, ...prev]);
      setShowCompetitorUrlModal(false);
      resetCompetitorUrlForm();
      toast.success('Competitor URL added successfully');
    } catch (err) {
      console.error('Error adding competitor URL:', err);
      setError('Failed to add competitor URL');
      toast.error('Failed to add competitor URL');
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdateCompetitorUrl = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!editingCompetitorUrl) return;
    
    try {
      setIsSaving(true);
      setError(null);
      
      // Convert sales estimate to number or null
      const salesEstimate = competitorUrlFormData.sales_estimate ? 
        parseInt(competitorUrlFormData.sales_estimate, 10) : 
        undefined;
      
      const updatedUrl = await updateCompetitorUrl(editingCompetitorUrl.id, {
        url: competitorUrlFormData.url,
        name: competitorUrlFormData.name || undefined,
        sales_estimate: salesEstimate,
        notes: competitorUrlFormData.notes || undefined
      });
      
      setCompetitorUrls(prev => 
        prev.map(url => url.id === updatedUrl.id ? updatedUrl : url)
      );
      
      setShowCompetitorUrlModal(false);
      setEditingCompetitorUrl(null);
      resetCompetitorUrlForm();
      toast.success('Competitor URL updated successfully');
    } catch (err) {
      console.error('Error updating competitor URL:', err);
      setError('Failed to update competitor URL');
      toast.error('Failed to update competitor URL');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteCompetitorUrl = async (urlId: number) => {
    if (!window.confirm('Are you sure you want to delete this competitor URL?')) return;
    
    try {
      await deleteCompetitorUrl(urlId);
      setCompetitorUrls(prev => prev.filter(url => url.id !== urlId));
      toast.success('Competitor URL deleted successfully');
    } catch (err) {
      console.error('Error deleting competitor URL:', err);
      toast.error('Failed to delete competitor URL');
    }
  };

  const resetCompetitorUrlForm = () => {
    setCompetitorUrlFormData({
      url: '',
      name: '',
      sales_estimate: '',
      notes: ''
    });
  };

  const openAddCompetitorUrlModal = () => {
    resetCompetitorUrlForm();
    setEditingCompetitorUrl(null);
    setShowCompetitorUrlModal(true);
  };

  const openEditCompetitorUrlModal = (url: CompetitorUrl) => {
    setCompetitorUrlFormData({
      url: url.url,
      name: url.name || '',
      sales_estimate: url.sales_estimate ? url.sales_estimate.toString() : '',
      notes: url.notes || ''
    });
    setEditingCompetitorUrl(url);
    setShowCompetitorUrlModal(true);
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  if (error && !item) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          <p>{error}</p>
          <Link to="/saved" className="text-blue-600 hover:underline mt-2 inline-block">
            Back to Saved Items
          </Link>
        </div>
      </div>
    );
  }

  if (!item) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded">
          <p>Item not found</p>
          <Link to="/saved" className="text-blue-600 hover:underline mt-2 inline-block">
            Back to Saved Items
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-5xl">
      <div className="mb-6 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-800">Edit Item Details</h1>
        <Link 
          to="/saved" 
          className="flex items-center text-blue-600 hover:text-blue-800 transition-colors"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
          </svg>
          Back to Saved Items
        </Link>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          <p>{error}</p>
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Left column - Image and Links */}
        <div className="lg:col-span-1">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="p-6">
              <div className="mb-6">
                <h2 className="text-lg font-semibold text-gray-800 mb-4">Thumbnail</h2>
                <div className="bg-gray-100 rounded-lg overflow-hidden">
                  {formData.thumbnail_url && !imageError ? (
                    <img
                      src={formData.thumbnail_url}
                      alt={formData.title || 'Item thumbnail'}
                      className="w-full h-48 object-cover"
                      onError={handleImageError}
                    />
                  ) : (
                    <div className="w-full h-48 flex items-center justify-center bg-gray-200">
                      <p className="text-gray-500 text-center">
                        <span className="block text-sm font-medium">Image not available</span>
                        <span className="block text-xs mt-1">{formData.title || 'No title available'}</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-6">
                <h2 className="text-lg font-semibold text-gray-800 mb-4">Links</h2>
                <div className="flex flex-col space-y-3">
                  <a 
                    href={formData.thingiverse_url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center text-blue-600 hover:text-blue-800 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center bg-blue-100 rounded-full mr-2 group-hover:bg-blue-200 transition-colors">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-600" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M12.75 11.25H9c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h3.75c.41 0 .75.34.75.75s-.34.75-.75.75zm3 3H9c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h6.75c.41 0 .75.34.75.75s-.34.75-.75.75z"/>
                        <path d="M12 21.75c-5.38 0-9.75-4.37-9.75-9.75S6.62 2.25 12 2.25s9.75 4.37 9.75 9.75-4.37 9.75-9.75 9.75zm0-18c-4.55 0-8.25 3.7-8.25 8.25s3.7 8.25 8.25 8.25 8.25-3.7 8.25-8.25-3.7-8.25-8.25-8.25z"/>
                      </svg>
                    </div>
                    <span>View on Thingiverse</span>
                  </a>
                  
                  <a 
                    href={formData.amazon_search_url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center text-blue-600 hover:text-blue-800 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center bg-yellow-100 rounded-full mr-2 group-hover:bg-yellow-200 transition-colors">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-700" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M21.5 11.5c-.17 0-.34.01-.5.03V7c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1v1H8V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4.53c-.16-.02-.33-.03-.5-.03-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5c1.93 0 3.5-1.57 3.5-3.5 0-.95-.38-1.81-1-2.44V9h7v2.56c-.62.63-1 1.49-1 2.44 0 1.93 1.57 3.5 3.5 3.5 1.93 0 3.5-1.57 3.5-3.5 0-.95-.38-1.81-1-2.44V9h2v2.56c-.62.63-1 1.49-1 2.44 0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zM5 7h2v2H5V7zm14 0h2v2h-2V7z"/>
                      </svg>
                    </div>
                    <span>Search on Amazon</span>
                  </a>
                  
                  <a 
                    href={formData.ebay_search_url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center text-blue-600 hover:text-blue-800 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center bg-red-100 rounded-full mr-2 group-hover:bg-red-200 transition-colors">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-600" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M19 6H5c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 10H5V8h14v8z"/>
                        <path d="M7 9h2v6H7zm4 0h2v6h-2zm4 0h2v6h-2z"/>
                      </svg>
                    </div>
                    <span>Search on eBay</span>
                  </a>
                </div>
              </div>

              {/* Likes display (moved from form to info panel) */}
              <div className="mb-6">
                <h2 className="text-lg font-semibold text-gray-800 mb-4">Stats</h2>
                <div className="flex items-center bg-gray-100 rounded-lg p-4">
                  <div className="w-8 h-8 flex items-center justify-center bg-pink-100 rounded-full mr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-pink-600" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div>
                    <span className="text-sm text-gray-500">Thingiverse Likes</span>
                    <p className="text-lg font-medium">{formData.popularity || 0}</p>
                  </div>
                </div>
              </div>

              <div className="mb-6">
                <h2 className="text-lg font-semibold text-gray-800 mb-4">Actions</h2>
                <div className="flex flex-col space-y-3">
                  <button
                    type="button"
                    onClick={handleFetchFiles}
                    disabled={isLoadingFiles}
                    className="flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
                  >
                    {isLoadingFiles ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Fetching Files...
                      </>
                    ) : (
                      <>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                        Fetch & Save Files
                      </>
                    )}
                  </button>
                  
                  <button
                    type="button"
                    onClick={handleDelete}
                    className="flex items-center justify-center px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>
                    Delete Item
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right column - Form */}
        <div className="lg:col-span-2">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="p-6">
              <div className="flex flex-col flex-grow">
                <form 
                  id="item-detail-form"
                  onSubmit={handleSubmit} 
                  className="flex flex-col flex-grow space-y-6"
                >
                  <div>
                    <label className="block text-gray-700 font-medium mb-2">
                      Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={formData.title || ''}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700 font-medium mb-2">
                      Thingiverse ID
                    </label>
                    <input
                      type="number"
                      name="thingiverse_id"
                      value={formData.thingiverse_id || ''}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                      disabled
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700 font-medium mb-2">
                      Category
                    </label>
                    <input
                      type="text"
                      name="category"
                      value={formData.category || ''}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700 font-medium mb-2">
                      Supplier
                    </label>
                    {isLoadingSuppliers ? (
                      <div className="flex items-center">
                        <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500 mr-2"></div>
                        <span className="text-sm text-gray-500">Loading suppliers...</span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <select
                          name="supplier_id"
                          value={formData.supplier_id || ''}
                          onChange={handleSupplierChange}
                          className="flex-grow px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="">-- Select a supplier --</option>
                          {suppliers.map((supplier) => (
                            <option key={supplier.id} value={supplier.id}>
                              {supplier.name}
                            </option>
                          ))}
                        </select>
                        
                        {formData.supplier_id && (
                          <Link 
                            to={`/suppliers/${formData.supplier_id}`}
                            className="ml-2 px-3 py-2 bg-blue-100 text-blue-600 rounded-lg hover:bg-blue-200 transition-colors text-sm"
                          >
                            View Details
                          </Link>
                        )}
                      </div>
                    )}
                    <p className="mt-1 text-sm text-gray-500">
                      Associate this item with a supplier for inventory management
                    </p>
                  </div>

                  <div>
                    <label className="block text-gray-700 font-medium mb-2">
                      Status
                    </label>
                    <select
                      name="status"
                      value={formData.status || 'active'}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="active" className="text-green-600">Active</option>
                      <option value="inactive" className="text-gray-600">Inactive</option>
                      <option value="inventory" className="text-yellow-600">Inventory</option>
                    </select>
                    <div className="mt-2 flex items-center">
                      <div className={`w-3 h-3 rounded-full mr-2 ${
                        formData.status === 'inactive' 
                          ? 'bg-gray-500' 
                          : formData.status === 'inventory' 
                          ? 'bg-yellow-500' 
                          : 'bg-green-500'
                      }`}></div>
                      <span className="text-sm text-gray-600">
                        {formData.status === 'active' 
                          ? 'Active items are currently being worked on or sold' 
                          : formData.status === 'inactive' 
                          ? 'Inactive items are not currently being pursued' 
                          : 'Inventory items are finished and in stock'}
                      </span>
                    </div>
                  </div>

                  <div>
                    <label className="block text-gray-700 font-medium mb-2">
                      Description
                    </label>
                    <textarea
                      name="description"
                      value={formData.description || ''}
                      onChange={handleChange}
                      rows={6}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    ></textarea>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="is_favorite"
                      name="is_favorite"
                      checked={formData.is_favorite || false}
                      onChange={handleChange}
                      className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <label htmlFor="is_favorite" className="ml-2 block text-gray-700 font-medium">
                      Mark as Favorite
                    </label>
                  </div>

                  {/* Hidden fields for URLs and popularity */}
                  <input type="hidden" name="thumbnail_url" value={formData.thumbnail_url || ''} />
                  <input type="hidden" name="thingiverse_url" value={formData.thingiverse_url || ''} />
                  <input type="hidden" name="amazon_search_url" value={formData.amazon_search_url || ''} />
                  <input type="hidden" name="ebay_search_url" value={formData.ebay_search_url || ''} />
                  <input type="hidden" name="popularity" value={formData.popularity || 0} />

                  <div className="pt-4">
                    <button
                      type="submit"
                      disabled={isSaving}
                      className="w-full flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
                    >
                      {isSaving ? (
                        <>
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Saving Changes...
                        </>
                      ) : (
                        <>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" />
                          </svg>
                          Save Changes
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Files section - show when files are available and showFiles is true */}
      {showFiles && (
        <div className="mt-8">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="p-6">
              <h2 className="text-xl font-bold text-gray-800 mb-4">Files ({files.length})</h2>
              
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                  <p>{error}</p>
                </div>
              )}
              
              {files.length === 0 ? (
                <p className="text-gray-500">No files available for this item.</p>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {files.map((file) => (
                    <div key={file.id} className="border rounded-lg overflow-hidden bg-gray-50 hover:bg-gray-100 transition-colors">
                      <div className="p-4">
                        <div className="flex items-start mb-3">
                          {file.thumbnail ? (
                            <img 
                              src={file.thumbnail} 
                              alt={file.name}
                              className="w-16 h-16 object-cover rounded mr-3"
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.src = 'https://cdn.thingiverse.com/site/img/default/Gears_thumb_medium.jpg';
                              }}
                            />
                          ) : (
                            <div className="w-16 h-16 flex items-center justify-center bg-gray-200 rounded mr-3">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                              </svg>
                            </div>
                          )}
                          <div className="flex-1 min-w-0">
                            <h3 className="text-sm font-medium text-gray-900 truncate" title={file.name}>
                              {file.name}
                            </h3>
                            <p className="text-xs text-gray-500">{file.formatted_size}</p>
                          </div>
                        </div>
                        
                        <div className="flex flex-col space-y-2">
                          <a 
                            href={`${API_BASE_URL}/saved-items/files/${file.id}/download`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-sm text-blue-600 flex items-center hover:text-blue-800 transition-colors"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                            Download
                          </a>
                          <a 
                            href={file.public_url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-sm text-blue-600 flex items-center hover:text-blue-800 transition-colors"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                            </svg>
                            View on Thingiverse
                          </a>
                          <button
                            onClick={() => handleDeleteFile(file.id)}
                            className="text-sm text-red-600 flex items-center hover:text-red-800 transition-colors"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                            Delete
                          </button>
                        </div>
                        
                        <div className="mt-2 text-xs text-gray-500">
                          <p>Downloads: {file.download_count}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Competitor URLs section */}
      <div className="mt-8">
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-800">Competitor Products {competitorUrls.length > 0 && `(${competitorUrls.length})`}</h2>
              <button
                onClick={openAddCompetitorUrlModal}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
                Add Competitor
              </button>
            </div>
            
            {isLoadingCompetitorUrls ? (
              <div className="flex justify-center items-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : competitorUrls.length === 0 ? (
              <div className="py-8 text-center">
                <p className="text-gray-500 mb-4">No competitor products added yet.</p>
                <p className="text-sm text-gray-400">Add competitor products to track sales estimates and market research.</p>
              </div>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        URL
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Sales Estimate
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Notes
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {competitorUrls.map((url) => (
                      <tr key={url.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {url.name || '(No name)'}
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <a 
                            href={url.url} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="text-sm text-blue-600 hover:text-blue-900 truncate block max-w-sm"
                            title={url.url}
                          >
                            {url.url}
                          </a>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {url.sales_estimate ? `${url.sales_estimate.toLocaleString()} / month` : 'N/A'}
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-gray-500 truncate max-w-xs" title={url.notes || ''}>
                            {url.notes || 'No notes'}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() => openEditCompetitorUrlModal(url)}
                            className="text-indigo-600 hover:text-indigo-900 mr-4"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDeleteCompetitorUrl(url.id)}
                            className="text-red-600 hover:text-red-900"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Competitor URL Modal */}
      {showCompetitorUrlModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg w-full max-w-md mx-4">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900">
                  {editingCompetitorUrl ? 'Edit Competitor Product' : 'Add Competitor Product'}
                </h3>
                <button 
                  onClick={() => setShowCompetitorUrlModal(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              
              <form onSubmit={editingCompetitorUrl ? handleUpdateCompetitorUrl : handleAddCompetitorUrl}>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                      URL<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="url"
                      id="url"
                      name="url"
                      value={competitorUrlFormData.url}
                      onChange={handleCompetitorUrlChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="https://www.example.com/product"
                      required
                    />
                  </div>
                  
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Product Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={competitorUrlFormData.name}
                      onChange={handleCompetitorUrlChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Product name"
                    />
                  </div>
                  
                  <div>
                    <label htmlFor="sales_estimate" className="block text-sm font-medium text-gray-700">
                      Monthly Sales Estimate
                    </label>
                    <input
                      type="number"
                      id="sales_estimate"
                      name="sales_estimate"
                      value={competitorUrlFormData.sales_estimate}
                      onChange={handleCompetitorUrlChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="0"
                      min="0"
                    />
                  </div>
                  
                  <div>
                    <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                      Notes
                    </label>
                    <textarea
                      id="notes"
                      name="notes"
                      rows={3}
                      value={competitorUrlFormData.notes}
                      onChange={handleCompetitorUrlChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Additional notes about this competitor product"
                    ></textarea>
                  </div>
                  
                  <div className="flex justify-end space-x-3 pt-4">
                    <button
                      type="button"
                      onClick={() => setShowCompetitorUrlModal(false)}
                      className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSaving}
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                    >
                      {isSaving ? (
                        <>
                          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Saving...
                        </>
                      ) : (
                        <>{editingCompetitorUrl ? 'Update' : 'Save'}</>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemDetailPage; 