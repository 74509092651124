import React, { useState, useEffect } from 'react';
import { Product, ProductCreate, ProductUpdate } from '../types/inventory';

interface ProductModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (product: ProductCreate | ProductUpdate) => Promise<void>;
    product?: Product;
    title: string;
}

const ProductModal: React.FC<ProductModalProps> = ({ isOpen, onClose, onSubmit, product, title }) => {
    const [formData, setFormData] = useState<ProductCreate>({
        sku: '',
        title: '',
        description: '',
        quantity_available: 0,
        quantity_reserved: 0,
        quantity_in_transit: 0,
        quantity_at_fba: 0,
        cost_price: 0,
        selling_price: 0,
        product_details: {},
    });

    useEffect(() => {
        if (product) {
            setFormData({
                sku: product.sku,
                title: product.title,
                description: product.description || '',
                quantity_available: product.quantity_available,
                quantity_reserved: product.quantity_reserved,
                quantity_in_transit: product.quantity_in_transit,
                quantity_at_fba: product.quantity_at_fba,
                cost_price: product.cost_price,
                selling_price: product.selling_price,
                product_details: product.product_details,
            });
        } else {
            setFormData({
                sku: '',
                title: '',
                description: '',
                quantity_available: 0,
                quantity_reserved: 0,
                quantity_in_transit: 0,
                quantity_at_fba: 0,
                cost_price: 0,
                selling_price: 0,
                product_details: {},
            });
        }
    }, [product]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await onSubmit(formData);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value === '' ? 0 : parseFloat(value)
        }));
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 w-full max-w-lg">
                <h2 className="text-xl font-bold mb-4">{title}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">SKU</label>
                            <input
                                type="text"
                                name="sku"
                                value={formData.sku}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Title</label>
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                required
                            />
                        </div>
                        
                        {/* Only show additional fields when editing an existing product */}
                        {product && (
                            <>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Description</label>
                                    <textarea
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        rows={3}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Available Quantity</label>
                                    <input
                                        type="number"
                                        name="quantity_available"
                                        value={formData.quantity_available}
                                        onChange={handleNumberChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Reserved Quantity</label>
                                    <input
                                        type="number"
                                        name="quantity_reserved"
                                        value={formData.quantity_reserved}
                                        onChange={handleNumberChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">In Transit Quantity</label>
                                    <input
                                        type="number"
                                        name="quantity_in_transit"
                                        value={formData.quantity_in_transit}
                                        onChange={handleNumberChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">FBA Quantity</label>
                                    <input
                                        type="number"
                                        name="quantity_at_fba"
                                        value={formData.quantity_at_fba}
                                        onChange={handleNumberChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Cost Price</label>
                                    <input
                                        type="number"
                                        step="0.01"
                                        name="cost_price"
                                        value={formData.cost_price}
                                        onChange={handleNumberChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Selling Price</label>
                                    <input
                                        type="number"
                                        step="0.01"
                                        name="selling_price"
                                        value={formData.selling_price}
                                        onChange={handleNumberChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="mt-6 flex justify-end space-x-3">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-200 hover:bg-gray-300 text-gray-800 px-4 py-2 rounded"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                        >
                            {product ? 'Update' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProductModal; 